import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";

const SecondPage = ({ state, dispatch }) => {
  const [videoConfirm, setVideoConfirm] = useState(state.videoConfirm || false);

  useScrollTo(0, 0, "smooth");

  const onVideoConfirmChange = ({ target }) => {
    setVideoConfirm(!videoConfirm);
    dispatch({ type: "VIDEO_CONFIRM", payload: target.checked });
  };

  return (
    <>
      <div className="page-one-header">
        <h3 className="text-center mx-10">How to submit your application</h3>
        <h2 className="text-center mx-10">Low Income/Self-employed</h2>
        <p>
          Please watch this short cartoon video explaining what evidence you
          need before making the online application.{" "}
          <strong>It is important you watch it. </strong>
        </p>
        <div className="video-responsive m-25">
          <iframe
            width="853"
            height="480"
            src="https://www.youtube.com/embed/lEWcDZDML2I"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <div className="check-box">
          <input
            type="checkbox"
            checked={videoConfirm}
            onChange={onVideoConfirmChange}
            id="video-confirm"
            required
          />
          <label htmlFor="video-confirm">
            Please confirm that you have watched this video and you have the
            required evidence.
          </label>
        </div>
      </div>
    </>
  );
};

export default SecondPage;
