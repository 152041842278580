import { useEffect, useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import YesNoInput from "../Components/FormComponents/YesNoInput";

const CurrentPartnerEvidence = ({ state, dispatch }) => {
  const [checkEvidenceOne, setCheckEvidenceOne] = useState(
    state.checkEvidenceOne
  );
  const [checkEvidenceTwo, setCheckEvidenceTwo] = useState(
    state.checkEvidenceTwo
  );
  const [checkEvidenceThree, setCheckEvidenceThree] = useState(
    state.checkEvidenceThree
  );
  const [checkEvidenceFour, setCheckEvidenceFour] = useState(
    state.checkEvidenceFour
  );
  const [checkEvidenceFive, setCheckEvidenceFive] = useState(
    state.checkEvidenceFive
  );
  const [checkEvidenceSix, setCheckEvidenceSix] = useState(
    state.checkEvidenceSix
  );
  const [checkEvidenceSeven, setCheckEvidenceSeven] = useState(
    state.checkEvidenceSeven
  );
  const [checkEvidenceEight, setCheckEvidenceEight] = useState(
    state.checkEvidenceEight
  );
  const [checkEvidenceNine, setCheckEvidenceNine] = useState(
    state.checkEvidenceNine
  );
  const [evidenceExOne, setEvidenceExOne] = useState(state.evidenceExOne);
  const [evidenceExTwo, setEvidenceExTwo] = useState(state.evidenceExTwo);
  const [evidenceExThree, setEvidenceExThree] = useState(state.evidenceExThree);
  const [evidenceExFour, setEvidenceExFour] = useState(state.evidenceExFour);
  const [evidenceExFive, setEvidenceExFive] = useState(state.evidenceExFive);
  const [evidenceExSix, setEvidenceExSix] = useState(state.evidenceExSix);
  const [evidenceExSeven, setEvidenceExSeven] = useState(state.evidenceExSeven);
  const [evidenceExEight, setEvidenceExEight] = useState(state.evidenceExEight);
  const [evidenceExNine, setEvidenceExNine] = useState(state.evidenceExNine);
  const [evidenceExTen, setEvidenceExTen] = useState(state.evidenceExTen);
  const [errorOne, setErrorOne] = useState("");
  const [errorTwo, setErrorTwo] = useState("");
  const [errorThree, setErrorThree] = useState("");
  const [errorFour, setErrorFour] = useState("");
  const [errorFive, setErrorFive] = useState("");
  const [errorSix, setErrorSix] = useState("");
  const [errorSeven, setErrorSeven] = useState("");
  const [errorEight, setErrorEight] = useState("");
  const [errorNine, setErrorNine] = useState("");
  const [errorTen, setErrorTen] = useState("");

  useEffect(() => {
    if (state?.evidenceExOne?.name === undefined) {
      setErrorOne(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorOne("");
    }
    if (state?.evidenceExTwo?.name === undefined) {
      setErrorTwo(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorTwo("");
    }
    if (state?.evidenceExThree?.name === undefined) {
      setErrorThree(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorThree("");
    }
    if (state?.evidenceExFour?.name === undefined) {
      setErrorFour(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorFour("");
    }
    if (state?.evidenceExFive?.name === undefined) {
      setErrorFive(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorFive("");
    }
    if (state?.evidenceExSix?.name === undefined) {
      setErrorSix(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorSix("");
    }
    if (state?.evidenceExSeven?.name === undefined) {
      setErrorSeven(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorSeven("");
    }
    if (state?.evidenceExEight?.name === undefined) {
      setErrorEight(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorEight("");
    }
    if (state?.evidenceExNine?.name === undefined) {
      setErrorNine(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorNine("");
    }
    if (state?.evidenceExTen?.name === undefined) {
      setErrorTen(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorTen("");
    }
  }, [
    state?.evidenceExOne?.name,
    state?.evidenceExTwo?.name,
    state?.evidenceExThree?.name,
    state?.evidenceExFour?.name,
    state?.evidenceExFive?.name,
    state?.evidenceExSix?.name,
    state?.evidenceExSeven?.name,
    state?.evidenceExEight?.name,
    state?.evidenceExNine?.name,
    state?.evidenceExTen?.name,
  ]);

  useScrollTo(0, 0, "smooth");
  const onEvidenceOneHandler = (event) => {
    setEvidenceExOne(event.target.files[0]);
  };
  const onEvidenceTwoHandler = (event) => {
    setEvidenceExTwo(event.target.files[0]);
  };
  const onEvidenceThreeHandler = (event) => {
    setEvidenceExThree(event.target.files[0]);
  };
  const onEvidenceFourHandler = (event) => {
    setEvidenceExFour(event.target.files[0]);
  };
  const onEvidenceFiveHandler = (event) => {
    setEvidenceExFive(event.target.files[0]);
  };
  const onEvidenceSixHandler = (event) => {
    setEvidenceExSix(event.target.files[0]);
  };
  const onEvidenceSevenHandler = (event) => {
    setEvidenceExSeven(event.target.files[0]);
  };
  const onEvidenceEightHandler = (event) => {
    setEvidenceExEight(event.target.files[0]);
  };
  const onEvidenceNineHandler = (event) => {
    setEvidenceExNine(event.target.files[0]);
  };
  const onEvidenceTenHandler = (event) => {
    setEvidenceExTen(event.target.files[0]);
  };

  const onCheckOne = ({ target }) => {
    setCheckEvidenceOne(target.value);
    dispatch({ type: "CHECK_EVIDENCE_ONE", payload: target.value });
  };
  const onCheckTwo = ({ target }) => {
    setCheckEvidenceTwo(target.value);
    dispatch({ type: "CHECK_EVIDENCE_TWO", payload: target.value });
  };
  const onCheckThree = ({ target }) => {
    setCheckEvidenceThree(target.value);
    dispatch({ type: "CHECK_EVIDENCE_THREE", payload: target.value });
  };
  const onCheckFour = ({ target }) => {
    setCheckEvidenceFour(target.value);
    dispatch({ type: "CHECK_EVIDENCE_FOUR", payload: target.value });
  };
  const onCheckFive = ({ target }) => {
    setCheckEvidenceFive(target.value);
    dispatch({ type: "CHECK_EVIDENCE_FIVE", payload: target.value });
  };
  const onCheckSix = ({ target }) => {
    setCheckEvidenceSix(target.value);
    dispatch({ type: "CHECK_EVIDENCE_SIX", payload: target.value });
  };
  const onCheckSeven = ({ target }) => {
    setCheckEvidenceSeven(target.value);
    dispatch({ type: "CHECK_EVIDENCE_SEVEN", payload: target.value });
  };
  const onCheckEight = ({ target }) => {
    setCheckEvidenceEight(target.value);
    dispatch({ type: "CHECK_EVIDENCE_EIGHT", payload: target.value });
  };
  const onCheckNine = ({ target }) => {
    setCheckEvidenceNine(target.value);
    dispatch({ type: "CHECK_EVIDENCE_NINE", payload: target.value });
  };

  useEffect(() => {
    dispatch({ type: "Ex_EVIDENCE_ONE", payload: evidenceExOne });
    dispatch({ type: "Ex_EVIDENCE_TWO", payload: evidenceExTwo });
    dispatch({ type: "Ex_EVIDENCE_THREE", payload: evidenceExThree });
    dispatch({ type: "Ex_EVIDENCE_FOUR", payload: evidenceExFour });
    dispatch({ type: "Ex_EVIDENCE_FIVE", payload: evidenceExFive });
    dispatch({ type: "Ex_EVIDENCE_SIX", payload: evidenceExSix });
    dispatch({ type: "Ex_EVIDENCE_SEVEN", payload: evidenceExSeven });
    dispatch({ type: "Ex_EVIDENCE_EIGHT", payload: evidenceExEight });
    dispatch({ type: "Ex_EVIDENCE_NINE", payload: evidenceExNine });
    dispatch({ type: "Ex_EVIDENCE_TEN", payload: evidenceExTen });
  }, [
    dispatch,
    evidenceExOne,
    evidenceExTwo,
    evidenceExThree,
    evidenceExFour,
    evidenceExFive,
    evidenceExSix,
    evidenceExSeven,
    evidenceExEight,
    evidenceExNine,
    evidenceExTen,
  ]);

  return (
    <>
      <h2>Current partner's evidence</h2>
      <br />
      <p>
        As you are cohabiting with a new partner, we need you to provide
        evidence in order calculate your household income. Please submit the
        evidence of your current partner's income.
      </p>
      <br />
      <p>
        If your current partner is in receipt of Universal Credit, Income
        Support, Jobseeker's Allowance, or Employment & Support Allowance,
        please submit an image or a PDF file of all the pages of the entitlement
        letter, or a screenshot of the last payment.
      </p>
      <br />
      <p>
        If your current partner is in employment, please submit the last month's
        bank statement of ALL their accounts, followed by the last month or last
        4 week's payslips.
      </p>
      <br />
      <>
        <h3>
          Current partner's evidence (1)<span className="color-red">*</span>
        </h3>
        <div className="form-control colum-direction">
          <label htmlFor="evidence-1" className="file-label">
            Upload File - {state?.evidenceExOne?.name}
          </label>
          <input
            type="file"
            name="evidence-1"
            id="evidence-1"
            onChange={onEvidenceOneHandler}
            required={state?.evidenceExOne?.name === undefined ? true : false}
            style={{
              display: "none",
            }}
          />
        </div>
        <p style={{ color: "red" }}>{errorOne}</p>
        <YesNoInput
          label="Do you have more documents to upload?"
          yesLabel="Yes"
          noLabel="No"
          yesId="yes-check-one"
          noId="no-check-one"
          name="check-one"
          checkedTerm={checkEvidenceOne}
          onOptionChange={onCheckOne}
          required={true}
        />
      </>
      {checkEvidenceOne === "Yes" && (
        <>
          <h3>
            Current partner's evidence (2)<span className="color-red">*</span>
          </h3>
          <div className="form-control colum-direction">
            <label htmlFor="evidence-2" className="file-label">
              Upload File - {state?.evidenceExTwo?.name}
            </label>
            <input
              type="file"
              name="evidence-2"
              id="evidence-2"
              onChange={onEvidenceTwoHandler}
              required={state?.evidenceExTwo?.name === undefined ? true : false}
              style={{
                display: "none",
              }}
            />
          </div>
          <p style={{ color: "red" }}>{errorTwo}</p>
          <YesNoInput
            label="Do you have more documents to upload?"
            yesLabel="Yes"
            noLabel="No"
            yesId="yes-check-two"
            noId="no-check-two"
            name="check-two"
            checkedTerm={checkEvidenceTwo}
            onOptionChange={onCheckTwo}
            required={true}
          />
        </>
      )}
      {checkEvidenceOne === "Yes" && checkEvidenceTwo === "Yes" && (
        <>
          <h3>
            Current partner's evidence (3)<span className="color-red">*</span>
          </h3>
          <div className="form-control colum-direction">
            <label htmlFor="evidence-3" className="file-label">
              Upload File - {state?.evidenceExThree?.name}
            </label>
            <input
              type="file"
              name="evidence-3"
              id="evidence-3"
              onChange={onEvidenceThreeHandler}
              required={
                state?.evidenceExThree?.name === undefined ? true : false
              }
              style={{
                display: "none",
              }}
            />
          </div>
          <p style={{ color: "red" }}>{errorThree}</p>
          <YesNoInput
            label="Do you have more documents to upload?"
            yesLabel="Yes"
            noLabel="No"
            yesId="yes-check-three"
            noId="no-check-three"
            name="check-three"
            checkedTerm={checkEvidenceThree}
            onOptionChange={onCheckThree}
            required={true}
          />
        </>
      )}
      {checkEvidenceOne === "Yes" &&
        checkEvidenceThree === "Yes" &&
        checkEvidenceTwo === "Yes" && (
          <>
            <h3>
              Current partner's evidence (4)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-4" className="file-label">
                Upload File - {state?.evidenceExFour?.name}
              </label>
              <input
                type="file"
                name="evidence-4"
                id="evidence-4"
                onChange={onEvidenceFourHandler}
                required={
                  state?.evidenceExFour?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
              />
            </div>
            <p style={{ color: "red" }}>{errorFour}</p>
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-four"
              noId="no-check-four"
              name="check-four"
              checkedTerm={checkEvidenceFour}
              onOptionChange={onCheckFour}
              required={true}
            />
          </>
        )}
      {checkEvidenceOne === "Yes" &&
        checkEvidenceThree === "Yes" &&
        checkEvidenceFour === "Yes" &&
        checkEvidenceTwo === "Yes" && (
          <>
            <h3>
              Current partner's evidence (5)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-5" className="file-label">
                Upload File - {state?.evidenceExFive?.name}
              </label>
              <input
                type="file"
                name="evidence-5"
                id="evidence-5"
                onChange={onEvidenceFiveHandler}
                required={
                  state?.evidenceExFive?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
              />
            </div>
            <p style={{ color: "red" }}>{errorFive}</p>
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-five"
              noId="no-check-five"
              name="check-five"
              checkedTerm={checkEvidenceFive}
              onOptionChange={onCheckFive}
              required={true}
            />
          </>
        )}
      {checkEvidenceOne === "Yes" &&
        checkEvidenceThree === "Yes" &&
        checkEvidenceFour === "Yes" &&
        checkEvidenceTwo === "Yes" &&
        checkEvidenceFive === "Yes" && (
          <>
            <h3>
              Current partner's evidence (6)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-6" className="file-label">
                Upload File - {state?.evidenceExSix?.name}
              </label>
              <input
                type="file"
                name="evidence-6"
                id="evidence-6"
                onChange={onEvidenceSixHandler}
                required={
                  state?.evidenceExSix?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
              />
            </div>
            <p style={{ color: "red" }}>{errorSix}</p>
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-six"
              noId="no-check-six"
              name="check-six"
              checkedTerm={checkEvidenceSix}
              onOptionChange={onCheckSix}
              required={true}
            />
          </>
        )}
      {checkEvidenceOne === "Yes" &&
        checkEvidenceThree === "Yes" &&
        checkEvidenceFour === "Yes" &&
        checkEvidenceTwo === "Yes" &&
        checkEvidenceFive === "Yes" &&
        checkEvidenceSix === "Yes" && (
          <>
            <h3>
              Current partner's evidence (7)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-7" className="file-label">
                Upload File - {state?.evidenceExSeven?.name}
              </label>
              <input
                type="file"
                name="evidence-7"
                id="evidence-7"
                onChange={onEvidenceSevenHandler}
                required={
                  state?.evidenceExSeven?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
              />
            </div>
            <p style={{ color: "red" }}>{errorSeven}</p>
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-seven"
              noId="no-check-seven"
              name="check-seven"
              checkedTerm={checkEvidenceSeven}
              onOptionChange={onCheckSeven}
              required={true}
            />
          </>
        )}
      {checkEvidenceOne === "Yes" &&
        checkEvidenceThree === "Yes" &&
        checkEvidenceFour === "Yes" &&
        checkEvidenceTwo === "Yes" &&
        checkEvidenceFive === "Yes" &&
        checkEvidenceSix === "Yes" &&
        checkEvidenceSeven === "Yes" && (
          <>
            <h3>
              Current partner's evidence (8)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-8" className="file-label">
                Upload File - {state?.evidenceExEight?.name}
              </label>
              <input
                type="file"
                name="evidence-8"
                id="evidence-8"
                onChange={onEvidenceEightHandler}
                required={
                  state?.evidenceExEight?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
              />
            </div>
            <p style={{ color: "red" }}>{errorEight}</p>
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-eight"
              noId="no-check-eight"
              name="check-eight"
              checkedTerm={checkEvidenceEight}
              onOptionChange={onCheckEight}
              required={true}
            />
          </>
        )}
      {checkEvidenceOne === "Yes" &&
        checkEvidenceThree === "Yes" &&
        checkEvidenceFour === "Yes" &&
        checkEvidenceTwo === "Yes" &&
        checkEvidenceFive === "Yes" &&
        checkEvidenceSix === "Yes" &&
        checkEvidenceSeven === "Yes" &&
        checkEvidenceEight === "Yes" && (
          <>
            <h3>
              Current partner's evidence (9)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-9" className="file-label">
                Upload File - {state?.evidenceExNine?.name}
              </label>
              <input
                type="file"
                name="evidence-9"
                id="evidence-9"
                onChange={onEvidenceNineHandler}
                required={
                  state?.evidenceExNine?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
              />
            </div>
            <p style={{ color: "red" }}>{errorNine}</p>
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-nine"
              noId="no-check-nine"
              name="check-nine"
              checkedTerm={checkEvidenceNine}
              onOptionChange={onCheckNine}
              required={true}
            />
          </>
        )}
      {checkEvidenceOne === "Yes" &&
        checkEvidenceThree === "Yes" &&
        checkEvidenceFour === "Yes" &&
        checkEvidenceTwo === "Yes" &&
        checkEvidenceFive === "Yes" &&
        checkEvidenceSix === "Yes" &&
        checkEvidenceSeven === "Yes" &&
        checkEvidenceEight === "Yes" &&
        checkEvidenceNine === "Yes" && (
          <>
            <h3>
              Current partner's evidence (10)
              <span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-10" className="file-label">
                Upload File - {state?.evidenceExTen?.name}
              </label>
              <input
                type="file"
                name="evidence-10"
                id="evidence-10"
                onChange={onEvidenceTenHandler}
                required={
                  state?.evidenceExTen?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
              />
            </div>
            <p style={{ color: "red" }}>{errorTen}</p>
          </>
        )}
    </>
  );
};

export default CurrentPartnerEvidence;
