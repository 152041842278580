import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../Components/FormComponents/TextInput";
import YesNoInput from "../Components/FormComponents/YesNoInput";

const FirstProperty = ({ state, dispatch }) => {
  const [firstPropertyWorth, setFirstPropertyWorth] = useState(
    state.firstPropertyWorth || ""
  );
  const [firstPropertyMortgage, setFirstPropertyMortgage] = useState(
    state.firstPropertyMortgage || ""
  );
  const [firstPropertyNames, setFirstPropertyNames] = useState(
    state.firstPropertyNames
  );
  const [firstPropertyShare, setFirstPropertyShare] = useState(
    state.firstPropertyShare || ""
  );
  const [firstPropertyDiscuss, setFirstPropertyDiscuss] = useState(
    state.firstPropertyDiscuss || ""
  );
  const [secondPropertyCheck, setSecondPropertyCheck] = useState(
    state.secondPropertyCheck || ""
  );

  useScrollTo(0, 0, "smooth");

  const onFirstPropertyWorthChange = ({ target }) => {
    setFirstPropertyWorth(target.value);
    dispatch({ type: "FIRST_PROPERTY_WORTH", payload: target.value });
  };
  const onFirstPropertyMortgageChange = ({ target }) => {
    setFirstPropertyMortgage(target.value);
    dispatch({ type: "FIRST_PROPERTY_MORTGAGE", payload: target.value });
  };
  const onFirstPropertyNamesChange = ({ target }) => {
    setFirstPropertyNames(target.value);
    dispatch({ type: "FIRST_PROPERTY_NAMES", payload: target.value });
  };
  const onFirstPropertyShareChange = ({ target }) => {
    setFirstPropertyShare(target.value);
    dispatch({ type: "FIRST_PROPERTY_SHARE", payload: target.value });
  };
  const onFirstPropertyDiscussChange = ({ target }) => {
    setFirstPropertyDiscuss(target.value);
    dispatch({ type: "FIRST_PROPERTY_DISCUSS", payload: target.value });
  };
  const onSecondPropertyCheck = ({ target }) => {
    setSecondPropertyCheck(target.value);
    dispatch({ type: "SECOND_PROPERTY_CHECK", payload: target.value });
  };

  return (
    <>
      <h2>The first property</h2>
      <TextInput
        label="What do you think the first property is worth?"
        required={true}
        id="firstPropertyWorth"
        value={firstPropertyWorth}
        onChangeText={onFirstPropertyWorthChange}
      />
      <TextInput
        label="What is the outstanding mortgage on the first property?"
        required={true}
        id="firstPropertyMortgage"
        value={firstPropertyMortgage}
        onChangeText={onFirstPropertyMortgageChange}
      />
      <YesNoInput
        label="Is the first property in joint names with your ex-partner?"
        required={true}
        name="firstPropertyNames"
        yesLabel="Yes"
        noLabel="No"
        yesId="yes-first-property-name"
        noId="no-first-property-name"
        checkedTerm={firstPropertyNames}
        onOptionChange={onFirstPropertyNamesChange}
      />
      <TextInput
        label="What is your share of the first property according to the deeds?"
        guide="Enter 50% when the property is jointly owned with your ex-partner.
        Select 100% when the property is only owned by you."
        id="firstPropertyShare"
        anotherText="Enter an appropriate % if another person has a percentage interest."
        value={firstPropertyShare}
        onChangeText={onFirstPropertyShareChange}
      />
      <YesNoInput
        label="Are you willing to discuss this property during mediation?"
        name="firstPropertyDiscuss"
        yesLabel="Yes"
        noLabel="No"
        yesId="yes-first-property-discussion"
        noId="no-first-property-discussion"
        required={true}
        checkedTerm={firstPropertyDiscuss}
        onOptionChange={onFirstPropertyDiscussChange}
      />
      <YesNoInput
        label="Do you own a second property?"
        name="secondPropertyCheck"
        yesLabel="Yes"
        noLabel="No"
        yesId="yes-second-property-check"
        noId="no-second-property-check"
        required={true}
        checkedTerm={secondPropertyCheck}
        onOptionChange={onSecondPropertyCheck}
      />
    </>
  );
};

export default FirstProperty;
