import { useState } from "react";
import YesNoInput from "../Components/FormComponents/YesNoInput";
import useScrollTo from "../hooks/useScrollTo";

const FirstPage = ({ state, dispatch }) => {
  const [iConsent, setIConsent] = useState(state.iConsent || "I consent");

  useScrollTo(0, 0, "smooth");

  const onIConsentChange = ({ target }) => {
    setIConsent(target.value);
    dispatch({ type: "I_Consent", payload: target.value });
  };

  return (
    <>
      <div className="page-one-header page-one">
        <h2 className="text-center">Your Legal Aid Assessment</h2>
        <h1 className="text-center">Low Income / Self-employed</h1>
      </div>
      <div className="first-page">
        <div className="first-page-left">
          <h4>Before we start...</h4>
          <p>
            As part of our commitment to your individual rights and freedoms
            under data privacy laws, we need to ask for your consent to collect
            and use the information you provide to us in this form. The
            information you provide in this form will be collected, processed,
            and used by us in accordance with our{" "}
            <a
              href="https://www.directmediationservices.co.uk/wp-content/uploads/2022/09/Privacy-Policy-v-1.1.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <p>
            For your information legal aid assessments could take up to seven
            days, depending on the complexity of your application.
          </p>
        </div>
        <div className="first-page-right">
          <YesNoInput
            label='By selecting "I consent" below, you confirm that you consent to our collection and use of your personal information.'
            required={true}
            checkedTerm={iConsent}
            onOptionChange={onIConsentChange}
            name="consent"
            yesLabel="I consent"
            noLabel="I do not consent"
            yesId="yes-consent"
            noId="no-consent"
          />
          <p>
            After completing each field, please click on the{" "}
            <strong>"Next"</strong> button to continue filling the form.{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default FirstPage;
