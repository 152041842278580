import { useState } from "react";
import YesNoInput from "../Components/FormComponents/YesNoInput";
import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../Components/FormComponents/TextInput";

const OtherDetails = ({ state, dispatch }) => {
  const [children, setChildren] = useState(state.children);
  const [childrenUnderFifteen, setChildrenUnderFifteen] = useState(
    state.childrenUnderFifteen || ""
  );
  const [childrenAboveFifteen, setChildrenAboveFifteen] = useState(
    state.childrenAboveFifteen || ""
  );
  const [financiallyResponsible, setFinanciallyResponsible] = useState(
    state.financiallyResponsible
  );
  const [childMaintenance, setChildMaintenance] = useState(
    state.childMaintenance
  );
  const [maintenancePay, setMaintenancePay] = useState(
    state.maintenancePay || ""
  );
  const [childCareSpent, setChildCareSpent] = useState(
    state.childCareSpent || ""
  );
  const [incomeContributionPayment, setIncomeContributionPayment] = useState(
    state.incomeContributionPayment
  );
  const [
    incomeContributionPaymentPerMonth,
    setIncomeContributionPaymentPerMonth,
  ] = useState(state.incomeContributionPaymentPerMonth || "");

  useScrollTo(0, 0, "smooth");

  const onChildrenChange = ({ target }) => {
    setChildren(target.value);
    dispatch({ type: "CHILDREN", payload: target.value });
  };
  const onChildrenUnderFifteen = ({ target }) => {
    setChildrenUnderFifteen(target.value);
    dispatch({ type: "CHILDREN_UNDER_FIFTEEN", payload: target.value });
  };
  const onChildrenAboveFifteen = ({ target }) => {
    setChildrenAboveFifteen(target.value);
    dispatch({ type: "CHILDREN_ABOVE_FIFTEEN", payload: target.value });
  };
  const onFinanciallyResponsibleChange = ({ target }) => {
    setFinanciallyResponsible(target.value);
    dispatch({ type: "FINANCIALLY_RESPONSIBLE", payload: target.value });
  };
  const onChildMaintenanceChange = ({ target }) => {
    setChildMaintenance(target.value);
    dispatch({ type: "CHILD_MAINTENANCE", payload: target.value });
  };
  const onMaintenancePayChange = ({ target }) => {
    setMaintenancePay(target.value);
    dispatch({ type: "MAINTENANCE_PAY", payload: target.value });
  };
  const onChildCareSpentChange = ({ target }) => {
    setChildCareSpent(target.value);
    dispatch({ type: "CHILD_CARE_SPENT", payload: target.value });
  };
  const onIncomeContributionPaymentChange = ({ target }) => {
    setIncomeContributionPayment(target.value);
    dispatch({ type: "INCOME_CONTRIBUTION_PAYMENT", payload: target.value });
  };
  const onIncomeContributionPaymentPerMonthChange = ({ target }) => {
    setIncomeContributionPaymentPerMonth(target.value);
    dispatch({
      type: "INCOME_CONTRIBUTION_PAYMENT_PER_MONTH",
      payload: target.value,
    });
  };

  return (
    <>
      <h2>Other details</h2>
      <YesNoInput
        label="Do you have any children living with you permanently?"
        required={true}
        guide="(This means you are in receipt of Child Benefits for this child/children)"
        name="children"
        checkedTerm={children}
        onOptionChange={onChildrenChange}
        noId="no-children"
        yesId="yes-children"
        noLabel="No"
        yesLabel="Yes"
      />
      {children === "Yes" && (
        <>
          <TextInput
            label="How many of your dependant children are younger than 15?"
            guide="Please put NIL or zero if this does not apply to you."
            id="childrenUnder15"
            required={true}
            value={childrenUnderFifteen}
            onChangeText={onChildrenUnderFifteen}
          />
          <TextInput
            label="How many of your dependant children are older than 15?"
            guide="Please put NIL or zero if this does not apply to you."
            id="childrenAbove15"
            required={true}
            value={childrenAboveFifteen}
            onChangeText={onChildrenAboveFifteen}
          />
        </>
      )}
      {(state.ownProperty === "Yes" || state.financialIssues === "Yes") && (
        <YesNoInput
          label="Are you financially responsible for your current partner?"
          required={true}
          name="financiallyResponsible"
          checkedTerm={financiallyResponsible}
          onOptionChange={onFinanciallyResponsibleChange}
          noId="no-financiallyResponsible"
          yesId="yes-financiallyResponsible"
          noLabel="No"
          yesLabel="Yes"
        />
      )}
      <YesNoInput
        label="Do you pay any child maintenance?"
        required={true}
        name="childMaintenance"
        checkedTerm={childMaintenance}
        onOptionChange={onChildMaintenanceChange}
        noLabel="No"
        yesLabel="Yes"
        noId="no-child-maintenance"
        yesId="yes-child-maintenance"
      />
      {childMaintenance === "Yes" && (
        <TextInput
          label="How much did you personally pay last month on maintenance?"
          guide="Please put NIL or zero if this does not apply to you."
          required={true}
          value={maintenancePay}
          onChangeText={onMaintenancePayChange}
          id="maintenancePay"
        />
      )}
      <TextInput
        label="How much did you personally spent on child care last month?"
        guide="Please put NIL or zero if this does not apply to you."
        required={true}
        value={childCareSpent}
        onChangeText={onChildCareSpentChange}
        id="childCareSpent"
      />
      <YesNoInput
        label="Do you make any payment of income contribution order (criminal legal aid)?"
        required={true}
        checkedTerm={incomeContributionPayment}
        name="incomeContributionPayment"
        onOptionChange={onIncomeContributionPaymentChange}
        noId="no-income-contribution"
        yesId="yes-income-contribution"
        noLabel="No"
        yesLabel="Yes"
      />
      {incomeContributionPayment === "Yes" && (
        <TextInput
          label="How much do you pay of income contribution order (criminal legal aid) per month?"
          required={true}
          guide="(Amount is £ per month)"
          id="incomeContributionPaymentPerMonth"
          value={incomeContributionPaymentPerMonth}
          onChangeText={onIncomeContributionPaymentPerMonthChange}
        />
      )}
    </>
  );
};

export default OtherDetails;
