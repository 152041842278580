import { Route, Routes } from "react-router-dom";
import Form from "../Components/Form";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Form />} />
      <Route path="/:id" element={<Form />} />
    </Routes>
  );
};

export default App;
