import { legalAidPatch } from "../Logic/legalAid";
import { client } from "../axios/axios";

export const uploadFileToDrive = (id, formStatePassport, setRedirect) => {
  const formData = new FormData();
  formData.append("files", formStatePassport.evidenceOne);
  formData.append("files", formStatePassport.evidenceTwo);
  formData.append("files", formStatePassport.evidenceThree);
  formData.append("files", formStatePassport.evidenceFour);
  formData.append("files", formStatePassport.evidenceFive);
  formData.append("files", formStatePassport.evidenceSix);
  formData.append("files", formStatePassport.evidenceSeven);
  formData.append("files", formStatePassport.evidenceEight);
  formData.append("files", formStatePassport.evidenceNine);
  formData.append("files", formStatePassport.evidenceTen);
  client
    .post(`/uploadFiles/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      setRedirect(true);
      localStorage.removeItem(`formStateLowIncome-${id}`);
    })
    .catch((err) => {
      console.log({ err });
    });
};

export const onBackHandler = (
  e,
  back,
  currentStepIndex,
  formStateLowIncome,
  goTo
) => {
  e.preventDefault();
  back();
  if (currentStepIndex === 5) {
    if (
      formStateLowIncome.status === "Cohabiting with new partner" ||
      formStateLowIncome.status === "Married to a new partner"
    ) {
      goTo(4);
    } else {
      goTo(3);
    }
  }
  if (currentStepIndex === 9) {
    if (
      formStateLowIncome.ownProperty === "Yes" ||
      formStateLowIncome.financialIssues === "Yes"
    ) {
      if (formStateLowIncome.thirdPropertyCheck === "Yes") {
        goTo(8);
      } else if (formStateLowIncome.secondPropertyCheck === "Yes") {
        goTo(7);
      } else {
        goTo(6);
      }
    } else {
      goTo(5);
    }
  }
  if (currentStepIndex === 12) {
    if (
      formStateLowIncome.status === "Cohabiting with new partner" ||
      formStateLowIncome.status === "Married to a new partner"
    ) {
      goTo(11);
    } else {
      goTo(10);
    }
  }
};

export const onSubmitHandler = (
  e,
  next,
  currentStepIndex,
  formStateLowIncome,
  goTo,
  steps,
  id,
  pathname,
  setIsSubmitted,
  setRedirect
) => {
  e.preventDefault();
  next();
  if (currentStepIndex === 3) {
    if (
      formStateLowIncome.status === "Cohabiting with new partner" ||
      formStateLowIncome.status === "Married to a new partner"
    ) {
      goTo(4);
    } else {
      goTo(5);
    }
  }
  if (currentStepIndex === 5) {
    if (
      formStateLowIncome.ownProperty === "Yes" ||
      formStateLowIncome.financialIssues === "Yes"
    ) {
      goTo(6);
    } else {
      goTo(9);
    }
  }
  if (currentStepIndex === 6) {
    if (formStateLowIncome.secondPropertyCheck === "Yes") {
      goTo(7);
    } else {
      goTo(9);
    }
  }
  if (currentStepIndex === 7) {
    if (formStateLowIncome.thirdPropertyCheck === "Yes") {
      goTo(8);
    } else {
      goTo(9);
    }
  }
  if (currentStepIndex === 10) {
    if (
      formStateLowIncome.status === "Cohabiting with new partner" ||
      formStateLowIncome.status === "Married to a new partner"
    ) {
      goTo(11);
    } else {
      goTo(12);
    }
  }
  if (currentStepIndex + 1 === steps.length) {
    legalAidPatch(
      formStateLowIncome,
      id,
      pathname,
      setIsSubmitted,
      setRedirect
    );
  }
};
