import { useMemo, useState } from "react";
import countryList from "react-select-country-list";

import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../Components/FormComponents/TextInput";
import YesNoInput from "../Components/FormComponents/YesNoInput";
import SelectInput from "../Components/FormComponents/SelectInput";

const YourPersonalDetails = ({ state, dispatch }) => {
  const findOptions = [
    "Google",
    "Support Through Court",
    "Legal Aid website",
    "Solicitor",
    "Mediator - Rachel Marsden",
    "FMC website",
    "Community Centre",
    "Facebook",
    "Friend/Family",
  ];

  const maritalStatusOptions = [
    "Married to the other participant in the conflict",
    "Civil partnered to the other participant in the conflict",
    "Divorced from the other participant in the conflict",
    "Separated from the other participant in the conflict",
    "Single",
    "Cohabiting with new partner",
    "Married to a new partner",
  ];

  const options = useMemo(() => countryList().getData(), []);
  let countries = [];
  options.map((option) => {
    countries.push(option.label);
    return countries;
  });

  const [firstName, setFirstName] = useState(state.firstName || "");
  const [surname, setSurname] = useState(state.surname || "");
  const [sameSurname, setSameSurname] = useState(state.sameSurname);
  const [birthSurname, setBirthSurname] = useState(state.birthSurname || "");
  const [DoB, setDoB] = useState(state.DoB || "");
  const [street, setStreet] = useState(state.street || "");
  const [city, setCity] = useState(state.city || "");
  const [postCode, setPostCode] = useState(state.postCode || "");
  const [country, setCountry] = useState(state.country || "United Kingdom");
  const [insuranceNumber, setInsuranceNumber] = useState(
    state.insuranceNumber || ""
  );
  const [phone, setPhone] = useState(state.phone || "");
  const [email, setEmail] = useState(state.email || "");
  const [find, setFind] = useState(state.find || "select");
  const [status, setStatus] = useState(state.status || "select");

  useScrollTo(0, 0, "smooth");

  const onFirstNameChange = ({ target }) => {
    setFirstName(target.value);
    dispatch({ type: "FIRST_NAME", payload: target.value });
  };
  const onSurnameChange = ({ target }) => {
    setSurname(target.value);
    dispatch({ type: "SURNAME", payload: target.value });
  };
  const onSameSurnameChange = ({ target }) => {
    setSameSurname(target.value);
    dispatch({ type: "SAME_SURNAME", payload: target.value });
  };
  const onBirthSurnameChange = ({ target }) => {
    setBirthSurname(target.value);
    dispatch({ type: "BIRTH_SURNAME", payload: target.value });
  };
  const onDoBChange = ({ target }) => {
    setDoB(target.value);
    dispatch({ type: "DOB", payload: target.value });
  };
  const onStreetChange = ({ target }) => {
    setStreet(target.value);
    dispatch({ type: "STREET", payload: target.value });
  };
  const onCityChange = ({ target }) => {
    setCity(target.value);
    dispatch({ type: "CITY", payload: target.value });
  };
  const onPostCodeChange = ({ target }) => {
    setPostCode(target.value);
    dispatch({ type: "POSTCODE", payload: target.value });
  };
  const onCountryChange = ({ target }) => {
    setCountry(target.value);
    dispatch({ type: "COUNTRY", payload: target.value });
  };
  const onInsuranceNumberChange = ({ target }) => {
    setInsuranceNumber(target.value);
    dispatch({ type: "INSURANCE_NUMBER", payload: target.value });
  };
  const onPhoneChange = ({ target }) => {
    setPhone(target.value);
    dispatch({ type: "PHONE", payload: target.value });
  };
  const onEmailChange = ({ target }) => {
    setEmail(target.value);
    dispatch({ type: "EMAIL", payload: target.value });
  };
  const onFindChange = ({ target }) => {
    setFind(target.value);
    dispatch({ type: "FIND", payload: target.value });
  };
  const onStatusChange = ({ target }) => {
    setStatus(target.value);
    dispatch({ type: "STATUS", payload: target.value });
  };

  return (
    <>
      <h2>Your personal details</h2>
      <p className="guide-label">
        Don’t worry we don’t give your information to anyone, only the Legal Aid
        Agency. This is a confidential process.
      </p>
      <div className="big-screen-layout">
        <TextInput
          label="What is your first name?"
          required={true}
          value={firstName}
          onChangeText={onFirstNameChange}
          id="first-name"
        />
        <TextInput
          label="What is your surname/family name?"
          required={true}
          value={surname}
          onChangeText={onSurnameChange}
          id="surname"
        />
      </div>
      <YesNoInput
        label="Was your surname/family name different at birth?"
        required={true}
        name="same-surname"
        checkedTerm={sameSurname}
        onOptionChange={onSameSurnameChange}
        noLabel="No"
        yesLabel="Yes"
        noId="no-same-surname"
        yesId="yes-same-surname"
      />
      {sameSurname === "Yes" && (
        <TextInput
          label="What was your surname/family name at birth?"
          required={true}
          value={birthSurname}
          onChangeText={onBirthSurnameChange}
          id="birth-surname"
        />
      )}
      <div className="form-control colum-direction">
        <label htmlFor="date-of-birth">
          What is your date of birth?<span className="color-red">*</span>
        </label>
        <input
          type="date"
          name="dob"
          id="date-of-birth"
          value={DoB}
          onChange={onDoBChange}
          required
          max={new Date().toLocaleDateString("fr-ca")}
        />
      </div>
      <h3 className="text-center">What is your home address?</h3>
      <div className="big-screen-layout">
        <TextInput
          label="House number & Street"
          value={street}
          onChangeText={onStreetChange}
          id="street"
          required={true}
        />
        <TextInput
          label="Town/City"
          value={city}
          onChangeText={onCityChange}
          id="city"
          required={true}
        />
      </div>
      <div className="big-screen-layout">
        <TextInput
          label="Postcode"
          value={postCode}
          onChangeText={onPostCodeChange}
          id="postCode"
          required={true}
        />
        <SelectInput
          label="COUNTRY"
          optionValue={country}
          options={countries}
          onOptionChange={onCountryChange}
          required={true}
        />
      </div>
      <div className="big-screen-layout">
        <TextInput
          label="What is your National Insurance Number?"
          required={true}
          value={insuranceNumber}
          onChangeText={onInsuranceNumberChange}
          guide="NINo comprises TWO letters followed by SIX numbers and ONE final letter."
          id="insuranceNumber"
          pattern="^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]?\s*$"
          anotherText="[If you do not know this, we cannot continue.]"
        />
        <TextInput
          label="What is your mobile telephone number?"
          required={true}
          value={phone}
          onChangeText={onPhoneChange}
          guide="Enter your mobile phone number without spaces. Example 07123456789"
          anotherText="This is the number the mediator will call you on."
          pattern="^(07[\d]{8,12}|447[\d]{7,11})$"
          id="phone"
        />
      </div>
      <div className="form-control colum-direction">
        <label htmlFor="email">
          What is your email address?<span className="color-red">*</span>
        </label>
        <input
          type="email"
          name="email"
          id="email"
          required
          value={email}
          onChange={onEmailChange}
        />
      </div>
      <SelectInput
        label="How did you find out about Direct Mediation Services?"
        options={findOptions}
        optionValue={find}
        onOptionChange={onFindChange}
        required={true}
        guide="Please choose from the list. by clicking on the box below."
      />
      <SelectInput
        label="Please select the options that apply to you regarding your marital status"
        guide="Please choose from the list. by clicking on the box below."
        required={true}
        optionValue={status}
        onOptionChange={onStatusChange}
        options={maritalStatusOptions}
      />
    </>
  );
};

export default YourPersonalDetails;
