import React, { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import SelectInput from "../Components/FormComponents/SelectInput";
import YesNoInput from "../Components/FormComponents/YesNoInput";
import TextInput from "../Components/FormComponents/TextInput";

const caseOptions = ["Child Arrangements", "Financial Arrangements", "Both"];
const accommodationTypeOptions = [
  "My own property",
  "Privately rented",
  "Housing association",
  "Council property",
  "Sheltered housing",
  "Temporary accommodation",
  "I live with friend or family and I pay rent",
  "I live with family/friend and don't pay rent",
];

const YourCases = ({ state, dispatch }) => {
  const [caseAbout, setCaseAbout] = useState(state.caseAbout || "select");
  const [financialIssues, setFinancialIssues] = useState(state.financialIssues);
  const [ownProperty, setOwnProperty] = useState(state.ownProperty);
  const [mortgagePay, setMortgagePay] = useState(state.mortgagePay || "");
  const [accommodationType, setAccommodationType] = useState(
    state.accommodationType || "select"
  );
  const [lastMonthPay, setLastMonthPay] = useState(state.lastMonthPay || "");

  useScrollTo(0, 0, "smooth");

  const onCaseAboutChange = ({ target }) => {
    setCaseAbout(target.value);
    dispatch({ type: "CASE_ABOUT", payload: target.value });
  };
  const onFinancialIssuesChange = ({ target }) => {
    setFinancialIssues(target.value);
    dispatch({ type: "FINANCIAL_ISSUE", payload: target.value });
  };
  const onOwnPropertyChange = ({ target }) => {
    setOwnProperty(target.value);
    dispatch({ type: "OWN_PROPERTY", payload: target.value });
  };
  const onMortgageChange = ({ target }) => {
    setMortgagePay(target.value);
    dispatch({ type: "MORTGAGE_PAY", payload: target.value });
  };
  const onAccommodationTypeChange = ({ target }) => {
    setAccommodationType(target.value);
    dispatch({ type: "ACCOMMODATION_TYPE", payload: target.value });
  };
  const onLastMonthPayChange = ({ target }) => {
    setLastMonthPay(target.value);
    dispatch({ type: "LAST_MONTH_PAY", payload: target.value });
  };

  return (
    <>
      <h2>Your case</h2>
      <SelectInput
        label="What is your case about?"
        required={true}
        optionValue={caseAbout}
        onOptionChange={onCaseAboutChange}
        options={caseOptions}
      />
      <YesNoInput
        label="Are you going to discuss financial issues such as family home and savings?"
        required={true}
        checkedTerm={financialIssues}
        onOptionChange={onFinancialIssuesChange}
        name="financialIssues"
        noLabel="No"
        yesLabel="Yes"
        noId="no-financial-issues"
        yesId="yes-financial-issues"
        guide="(This does NOT include CHILD Maintenance payments)"
      />
      <YesNoInput
        label="Do you own any property and your name is in the deeds?"
        required={true}
        name="own-property"
        yesLabel="Yes"
        noLabel="No"
        noId="no-own-property"
        yesId="yes-own-property"
        checkedTerm={ownProperty}
        onOptionChange={onOwnPropertyChange}
      />
      {ownProperty === "Yes" && (
        <TextInput
          label="How much did you personally pay of your mortgage last month?"
          required={true}
          guide="(Amount in £)"
          value={mortgagePay}
          onChangeText={onMortgageChange}
          id="mortgage-payment"
        />
      )}
      <SelectInput
        label="What type of accommodation do you live in?"
        guide="Please choose from the list, by clicking on the box below."
        options={accommodationTypeOptions}
        required={true}
        optionValue={accommodationType}
        onOptionChange={onAccommodationTypeChange}
      />
      {(accommodationType === "Privately rented" ||
        accommodationType === "Housing association" ||
        accommodationType === "Council property" ||
        accommodationType ===
          "I live with friend or family and I pay rent") && (
        <TextInput
          label="How much did you personally pay in rent last month?"
          guide="This does not include the amount from housing benefit. (Amount in £)"
          required={true}
          value={lastMonthPay}
          onChangeText={onLastMonthPayChange}
          id="lastMonthPay"
        />
      )}
    </>
  );
};

export default YourCases;
