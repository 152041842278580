import React from "react";
import { onBackHandler, onSubmitHandler } from "../Functions/navFunctions";
import { useLocation } from "react-router-dom";

const FormComponent = ({
  logo,
  currentStepIndex,
  step,
  formStateLowIncome,
  isFirstStep,
  steps,
  isLastStep,
  back,
  next,
  goTo,
  setIsSubmitted,
  id,
  setRedirect,
}) => {
  const { pathname } = useLocation();
  return (
    <div className="full-screen">
      <div className="form-screen">
        <div className="yellow-bar"></div>
        <header>
          <img src={logo} alt="Logo" />
        </header>
        <form
          className="form"
          onSubmit={(e) =>
            onSubmitHandler(
              e,
              next,
              currentStepIndex,
              formStateLowIncome,
              goTo,
              steps,
              id,
              pathname,
              setIsSubmitted,
              setRedirect
            )
          }
        >
          <div className="steps">
            {currentStepIndex + 1} / {steps.length}
          </div>
          {step}
          {formStateLowIncome.iConsent === "I do not consent" && (
            <div className="danger">
              <h2>Ok. This makes it a little hard to continue...</h2>
              <p>
                Because you don't agree to our data protection policy, we we are
                unable to assist you at this time.
                <strong>Your application won't be submitted.</strong>
              </p>
            </div>
          )}
          {currentStepIndex === 13 && !formStateLowIncome.url && (
            <p className="color-red">Please click save</p>
          )}
          <div>
            <div className="submit-btn submit">
              {!isFirstStep && (
                <button
                  type="button"
                  className="back-btn"
                  onClick={(e) =>
                    onBackHandler(
                      e,
                      back,
                      currentStepIndex,
                      formStateLowIncome,
                      goTo
                    )
                  }
                >
                  Back
                </button>
              )}
              <button
                type="submit"
                className="next-btn"
                disabled={
                  (currentStepIndex === 0 &&
                    formStateLowIncome.iConsent !== "I consent") ||
                  (currentStepIndex === 13 &&
                    formStateLowIncome.informationCheck === "No") ||
                  (currentStepIndex === 13 &&
                    formStateLowIncome.relatedDiscussionsUsed === "No") ||
                  (currentStepIndex === 13 &&
                    formStateLowIncome.refusalInformation === "No") ||
                  (currentStepIndex === 12 &&
                    formStateLowIncome.mediationBefore === "Yes" &&
                    formStateLowIncome.mediationType === "select") ||
                  (currentStepIndex === 3 &&
                    formStateLowIncome.find === "select") ||
                  (currentStepIndex === 3 &&
                    formStateLowIncome.status === "select") ||
                  (currentStepIndex === 5 &&
                    formStateLowIncome.caseAbout === "select") ||
                  (currentStepIndex === 5 &&
                    formStateLowIncome.accommodationType === "select") ||
                  (currentStepIndex === 13 && !formStateLowIncome.url)
                }
                style={{
                  filter:
                    ((currentStepIndex === 0 &&
                      formStateLowIncome.iConsent !== "I consent") ||
                      (currentStepIndex === 13 &&
                        formStateLowIncome.informationCheck === "No") ||
                      (currentStepIndex === 13 &&
                        formStateLowIncome.relatedDiscussionsUsed === "No") ||
                      (currentStepIndex === 13 &&
                        formStateLowIncome.refusalInformation === "No") ||
                      (currentStepIndex === 12 &&
                        formStateLowIncome.mediationBefore === "Yes" &&
                        formStateLowIncome.mediationType === "select") ||
                      (currentStepIndex === 3 &&
                        formStateLowIncome.find === "select") ||
                      (currentStepIndex === 3 &&
                        formStateLowIncome.status === "select") ||
                      (currentStepIndex === 5 &&
                        formStateLowIncome.caseAbout === "select") ||
                      (currentStepIndex === 5 &&
                        formStateLowIncome.accommodationType === "select") ||
                      (currentStepIndex === 13 && !formStateLowIncome.url)) &&
                    "opacity(.4)",
                }}
              >
                {isLastStep ? "Finish" : "Next"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormComponent;
