export const reducer = (state, { type, payload }) => {
  switch (type) {
    case "I_Consent":
      return { ...state, iConsent: payload };
    case "VIDEO_CONFIRM":
      return { ...state, videoConfirm: payload };
    case "CHECK_ONE":
      return { ...state, checkOne: payload };
    case "CHECK_TWO":
      return { ...state, checkTwo: payload };
    case "CHECK_THREE":
      return { ...state, checkThree: payload };
    case "CHECK_FOUR":
      return { ...state, checkFour: payload };
    case "CHECK_FIVE":
      return { ...state, checkFive: payload };
    case "CHECK_SIX":
      return { ...state, checkSix: payload };
    case "CHECK_SEVEN":
      return { ...state, checkSeven: payload };
    case "CHECK_EIGHT":
      return { ...state, checkEight: payload };
    case "CHECK_NINE":
      return { ...state, checkNine: payload };
    case "CHECK_EVIDENCE_ONE":
      return { ...state, checkEvidenceOne: payload };
    case "CHECK_EVIDENCE_TWO":
      return { ...state, checkEvidenceTwo: payload };
    case "CHECK_EVIDENCE_THREE":
      return { ...state, checkEvidenceThree: payload };
    case "CHECK_EVIDENCE_FOUR":
      return { ...state, checkEvidenceFour: payload };
    case "CHECK_EVIDENCE_FIVE":
      return { ...state, checkEvidenceFive: payload };
    case "CHECK_EVIDENCE_SIX":
      return { ...state, checkEvidenceSix: payload };
    case "CHECK_EVIDENCE_SEVEN":
      return { ...state, checkEvidenceSeven: payload };
    case "CHECK_EVIDENCE_EIGHT":
      return { ...state, checkEvidenceEight: payload };
    case "CHECK_EVIDENCE_NINE":
      return { ...state, checkEvidenceNine: payload };
    case "EVIDENCE_ONE":
      return { ...state, evidenceOne: payload };
    case "EVIDENCE_TWO":
      return { ...state, evidenceTwo: payload };
    case "EVIDENCE_THREE":
      return { ...state, evidenceThree: payload };
    case "EVIDENCE_FOUR":
      return { ...state, evidenceFour: payload };
    case "EVIDENCE_FIVE":
      return { ...state, evidenceFive: payload };
    case "EVIDENCE_SIX":
      return { ...state, evidenceSix: payload };
    case "EVIDENCE_SEVEN":
      return { ...state, evidenceSeven: payload };
    case "EVIDENCE_EIGHT":
      return { ...state, evidenceEight: payload };
    case "EVIDENCE_NINE":
      return { ...state, evidenceNine: payload };
    case "EVIDENCE_TEN":
      return { ...state, evidenceTen: payload };
    case "Ex_EVIDENCE_ONE":
      return { ...state, evidenceExOne: payload };
    case "Ex_EVIDENCE_TWO":
      return { ...state, evidenceExTwo: payload };
    case "Ex_EVIDENCE_THREE":
      return { ...state, evidenceExThree: payload };
    case "Ex_EVIDENCE_FOUR":
      return { ...state, evidenceExFour: payload };
    case "Ex_EVIDENCE_FIVE":
      return { ...state, evidenceExFive: payload };
    case "Ex_EVIDENCE_SIX":
      return { ...state, evidenceExSix: payload };
    case "Ex_EVIDENCE_SEVEN":
      return { ...state, evidenceExSeven: payload };
    case "Ex_EVIDENCE_EIGHT":
      return { ...state, evidenceExEight: payload };
    case "Ex_EVIDENCE_NINE":
      return { ...state, evidenceExNine: payload };
    case "Ex_EVIDENCE_TEN":
      return { ...state, evidenceExTen: payload };
    case "FIRST_NAME":
      return { ...state, firstName: payload };
    case "SURNAME":
      return { ...state, surname: payload };
    case "SAME_SURNAME":
      return { ...state, sameSurname: payload };
    case "BIRTH_SURNAME":
      return { ...state, birthSurname: payload };
    case "DOB":
      return { ...state, DoB: payload };
    case "STREET":
      return { ...state, street: payload };
    case "CITY":
      return { ...state, city: payload };
    case "POSTCODE":
      return { ...state, postCode: payload };
    case "COUNTRY":
      return { ...state, country: payload };
    case "INSURANCE_NUMBER":
      return { ...state, insuranceNumber: payload };
    case "PHONE":
      return { ...state, phone: payload };
    case "EMAIL":
      return { ...state, email: payload };
    case "FIND":
      return { ...state, find: payload };
    case "STATUS":
      return { ...state, status: payload };
    case "CASE_ABOUT":
      return { ...state, caseAbout: payload };
    case "FINANCIAL_ISSUE":
      return { ...state, financialIssues: payload };
    case "OWN_PROPERTY":
      return { ...state, ownProperty: payload };
    case "MORTGAGE_PAY":
      return { ...state, mortgagePay: payload };
    case "ACCOMMODATION_TYPE":
      return { ...state, accommodationType: payload };
    case "LAST_MONTH_PAY":
      return { ...state, lastMonthPay: payload };
    case "FIRST_PROPERTY_WORTH":
      return { ...state, firstPropertyWorth: payload };
    case "FIRST_PROPERTY_MORTGAGE":
      return { ...state, firstPropertyMortgage: payload };
    case "FIRST_PROPERTY_NAMES":
      return { ...state, firstPropertyNames: payload };
    case "FIRST_PROPERTY_SHARE":
      return { ...state, firstPropertyShare: payload };
    case "FIRST_PROPERTY_DISCUSS":
      return { ...state, firstPropertyDiscuss: payload };
    case "SECOND_PROPERTY_CHECK":
      return { ...state, secondPropertyCheck: payload };
    case "SECOND_PROPERTY_WORTH":
      return { ...state, secondPropertyWorth: payload };
    case "SECOND_PROPERTY_MORTGAGE":
      return { ...state, secondPropertyMortgage: payload };
    case "SECOND_PROPERTY_NAMES":
      return { ...state, secondPropertyNames: payload };
    case "SECOND_PROPERTY_SHARE":
      return { ...state, secondPropertyShare: payload };
    case "SECOND_PROPERTY_DISCUSS":
      return { ...state, secondPropertyDiscuss: payload };
    case "THIRD_PROPERTY_CHECK":
      return { ...state, thirdPropertyCheck: payload };
    case "THIRD_PROPERTY_WORTH":
      return { ...state, thirdPropertyWorth: payload };
    case "THIRD_PROPERTY_MORTGAGE":
      return { ...state, thirdPropertyMortgage: payload };
    case "THIRD_PROPERTY_NAMES":
      return { ...state, thirdPropertyNames: payload };
    case "THIRD_PROPERTY_SHARE":
      return { ...state, thirdPropertyShare: payload };
    case "THIRD_PROPERTY_DISCUSS":
      return { ...state, thirdPropertyDiscuss: payload };
    case "SAVE_MONEY":
      return { ...state, savingMoneyAmount: payload };
    case "INVESTMENT_MONEY":
      return { ...state, investmentMoneyAmount: payload };
    case "VALUABLE_ITEMS":
      return { ...state, valuableItemsWorth: payload };
    case "CAPITAL_VALUE":
      return { ...state, capitalValue: payload };
    case "CHILDREN":
      return { ...state, children: payload };
    case "CHILDREN_UNDER_FIFTEEN":
      return { ...state, childrenUnderFifteen: payload };
    case "CHILDREN_ABOVE_FIFTEEN":
      return { ...state, childrenAboveFifteen: payload };
    case "FINANCIALLY_RESPONSIBLE":
      return { ...state, financiallyResponsible: payload };
    case "CHILD_MAINTENANCE":
      return { ...state, childMaintenance: payload };
    case "MAINTENANCE_PAY":
      return { ...state, maintenancePay: payload };
    case "CHILD_CARE_SPENT":
      return { ...state, childCareSpent: payload };
    case "INCOME_CONTRIBUTION_PAYMENT":
      return { ...state, incomeContributionPayment: payload };
    case "INCOME_CONTRIBUTION_PAYMENT_PER_MONTH":
      return { ...state, incomeContributionPaymentPerMonth: payload };
    case "SAVE_MONEY_OTHER_PARTY":
      return { ...state, savingMoneyAmountOtherParty: payload };
    case "INVESTMENT_MONEY_OTHER_PARTY":
      return { ...state, investmentMoneyAmountOtherParty: payload };
    case "VALUABLE_ITEMS_OTHER_PARTY":
      return { ...state, valuableItemsWorthOtherParty: payload };
    case "CAPITAL_VALUE_OTHER_PARTY":
      return { ...state, capitalValueOtherParty: payload };
    case "OTHER_PARTY_SURNAME":
      return { ...state, otherPartySurname: payload };
    case "MARRIED_CHECK":
      return { ...state, marriedCheck: payload };
    case "MEDIATION_BEFORE":
      return { ...state, mediationBefore: payload };
    case "MEDIATION_TYPE":
      return { ...state, mediationType: payload };
    case "INFORMATION_CHECK":
      return { ...state, informationCheck: payload };
    case "REFUSAL_INFO":
      return { ...state, relatedDiscussionsUsed: payload };
    case "REFUSAL_DISCUSSION":
      return { ...state, refusalInformation: payload };
    case "URL":
      return { ...state, url: payload };
    default:
      return state;
  }
};

export const defaultState = {
  iConsent: "I consent",
  find: "select",
  status: "select",
  caseAbout: "select",
  accommodationType: "select",
  mediationType: "select",
  country: "United Kingdom",
};
