import React, { useEffect, useReducer, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import { useMultiStepForm } from "../hooks/useMultiStepForm";
import { defaultState, reducer } from "../Logic/reducer";

import logo from "../assets/dms-logotype2.png";

import FormComponent from "./FormComponent";

import FirstPage from "../Pages/FirstPage";
import SecondPage from "../Pages/SecondPage";
import Evidence from "../Pages/Evidence";
import CurrentPartnerEvidence from "../Pages/CurrentPartnerEvidence";
import YourPersonalDetails from "../Pages/YourPersonalDetails";
import YourCases from "../Pages/YourCases";
import FirstProperty from "../Pages/FirstProperty";
import SecondProperty from "../Pages/SecondProperty";
import ThirdProperty from "../Pages/ThirdProperty";
import FinancialDetails from "../Pages/FinancialDetails";
import OtherDetails from "../Pages/OtherDetails";
import PrevRelationshipDetails from "../Pages/PrevRelationshipDetails";
import ClientDeclaration from "../Pages/ClientDeclaration";
import PrevFinancialDetails from "../Pages/PrevFinancialDetails";
import Submitted from "../Pages/Submitted";

const Form = () => {
  const isMounted = useRef(false);
  const { id } = useParams();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [formStateLowIncome, dispatch] = useReducer(
    reducer,
    JSON.parse(localStorage.getItem(`formStateLowIncome-${id}`)) || defaultState
  );

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    localStorage.setItem(
      `formStateLowIncome-${id}`,
      JSON.stringify(formStateLowIncome)
    );
  }, [formStateLowIncome, id]);

  const {
    currentStepIndex,
    steps,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    goTo,
  } = useMultiStepForm([
    <FirstPage state={formStateLowIncome} dispatch={dispatch} />,
    <SecondPage state={formStateLowIncome} dispatch={dispatch} />,
    <Evidence state={formStateLowIncome} dispatch={dispatch} />,
    <YourPersonalDetails state={formStateLowIncome} dispatch={dispatch} />,
    <CurrentPartnerEvidence state={formStateLowIncome} dispatch={dispatch} />,
    <YourCases state={formStateLowIncome} dispatch={dispatch} />,
    <FirstProperty state={formStateLowIncome} dispatch={dispatch} />,
    <SecondProperty state={formStateLowIncome} dispatch={dispatch} />,
    <ThirdProperty state={formStateLowIncome} dispatch={dispatch} />,
    <FinancialDetails state={formStateLowIncome} dispatch={dispatch} />,
    <OtherDetails state={formStateLowIncome} dispatch={dispatch} />,
    <PrevFinancialDetails state={formStateLowIncome} dispatch={dispatch} />,
    <PrevRelationshipDetails state={formStateLowIncome} dispatch={dispatch} />,
    <ClientDeclaration state={formStateLowIncome} dispatch={dispatch} />,
  ]);

  return (
    <>
      {isSubmitted === true ? (
        <Submitted redirect={redirect} />
      ) : (
        <FormComponent
          logo={logo}
          currentStepIndex={currentStepIndex}
          formStateLowIncome={formStateLowIncome}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          step={step}
          steps={steps}
          back={back}
          next={next}
          goTo={goTo}
          setIsSubmitted={setIsSubmitted}
          id={id}
          setRedirect={setRedirect}
        />
      )}
    </>
  );
};

export default Form;
