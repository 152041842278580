import { useState } from "react";
import TextInput from "../Components/FormComponents/TextInput";
import useScrollTo from "../hooks/useScrollTo";

const FinancialDetails = ({ state, dispatch }) => {
  const [savingMoneyAmount, setSavingMoneyAmount] = useState(
    state.savingMoneyAmount || ""
  );
  const [investmentMoneyAmount, setInvestmentMoneyAmount] = useState(
    state.investmentMoneyAmount || ""
  );
  const [valuableItemsWorth, setValuableItemsWorth] = useState(
    state.valuableItemsWorth || ""
  );
  const [capitalValue, setCapitalValue] = useState(state.capitalValue || "");

  useScrollTo(0, 0, "smooth");

  const onSavingMoneyAmountChange = ({ target }) => {
    setSavingMoneyAmount(target.value);
    dispatch({ type: "SAVE_MONEY", payload: target.value });
  };
  const onInvestmentMoneyAmountChange = ({ target }) => {
    setInvestmentMoneyAmount(target.value);
    dispatch({ type: "INVESTMENT_MONEY", payload: target.value });
  };
  const onValuableItemsWorthChange = ({ target }) => {
    setValuableItemsWorth(target.value);
    dispatch({ type: "VALUABLE_ITEMS", payload: target.value });
  };
  const onCapitalValueChange = ({ target }) => {
    setCapitalValue(target.value);
    dispatch({ type: "CAPITAL_VALUE", payload: target.value });
  };

  return (
    <>
      <h2>Your financial details</h2>
      <TextInput
        label="Please tell us the amount of money you have in savings (bank, building society, etc)"
        required={true}
        value={savingMoneyAmount}
        onChangeText={onSavingMoneyAmountChange}
        id="savingMoneyAmount"
        guide="Please write it as a round figure."
      />
      <TextInput
        label="Please tell us the amount of money you have in investments (shares, insurance policies etc)"
        required={true}
        value={investmentMoneyAmount}
        onChangeText={onInvestmentMoneyAmountChange}
        id="investmentMoney"
        guide="Please write it as a round figure."
      />
      <TextInput
        label="Please tell us how much your valuable items are worth (boat, caravan, jewellery, etc) (This doesn't include cars unless their value is more than £15,000.00)."
        value={valuableItemsWorth}
        onChangeText={onValuableItemsWorthChange}
        id="valuableItemsWorth"
        guide="Please write it as a round figure."
      />
      <TextInput
        label="Please tell us the value of any other capital (including money due to the client)"
        required={true}
        value={capitalValue}
        onChangeText={onCapitalValueChange}
        id="capitalValue"
        guide="Please write it as a round figure."
      />
    </>
  );
};

export default FinancialDetails;
