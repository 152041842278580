import { uploadFileToDrive } from "../Functions/navFunctions";
import { client } from "../axios/axios";

export const legalAidPatch = (
  formStateLowIncome,
  id,
  pathname,
  setIsSubmitted,
  setRedirect
) => {
  const legalAidObject = {
    applicationType: "Low Income",
    pleaseConfirmThatYouHaveWatchedThisVideoAndYouHaveTheRequiredEvidence:
      formStateLowIncome.videoConfirm,
    evidence: [],
    personalDetails: {
      firstName: formStateLowIncome.firstName,
      surname: formStateLowIncome.surname,
      isSurnameAtBirthDifferent: formStateLowIncome.sameSurname,
      dateOfBirth: formStateLowIncome.DoB,
      address: {
        street: formStateLowIncome.street,
        city: formStateLowIncome.city,
        postcode: formStateLowIncome.postCode,
        country: formStateLowIncome.country,
      },
      nationalInsuranceNumber: formStateLowIncome.insuranceNumber,
      telephoneNumber: formStateLowIncome.phone,
      emailAddress: formStateLowIncome.email,
      findDMS: formStateLowIncome.find,
      maritalStatus: formStateLowIncome.status,
    },
    case: {
      caseAbout: formStateLowIncome.caseAbout,
      familyHomeAndSavingsDiscussion: formStateLowIncome.financialIssues,
      nameInDeedsProperty: formStateLowIncome.ownProperty,
      accommodationType: formStateLowIncome.accommodationType,
    },
    financialDetails: {
      savingsAmount: formStateLowIncome.savingMoneyAmount,
      investmentsAmount: formStateLowIncome.investmentMoneyAmount,
      valuableItemsWorth: formStateLowIncome.valuableItemsWorth,
      otherCapitalValue: formStateLowIncome.capitalValue,
    },
    otherDetails: {
      childrenLivingWithYouPermanently: formStateLowIncome.children,
      childMaintenancePayment: formStateLowIncome.childMaintenance,
      lastMonthChildCarePayment: formStateLowIncome.childCareSpent,
      criminalLegalAid: formStateLowIncome.incomeContributionPayment,
    },
    previousRelationshipDetails: {
      otherPersonSurname: formStateLowIncome.otherPartySurname,
      isMarriedTo: formStateLowIncome.marriedCheck,
      beenInMediationBefore: formStateLowIncome.mediationBefore,
    },
    clientDeclaration: {
      IUnderstandThatTheLegalAidAgencyMayCheckWhetherTheInformationAndDocumentationThatIProvideIsGenuineAndCorrect:
        "Yes",
      IUnderstandThatTheInformationObtainedWillBeUsedToDecideMyApplicationForLegalAidAndForRelatedPurposes:
        "Yes",
      IUnderstandThatProvidingInformationOrDocumentationThatIsNotGenuineAndOrCorrectWillResultInMyApplicationOrSubsequentApplicationsBeingRefusedAndMayLeadToMyProsecutionForACriminalOffence:
        "Yes",
      signature: formStateLowIncome.url,
    },
    properties: [],
  };

  if (
    legalAidObject.personalDetails.maritalStatus !==
      "Cohabiting with new partner" ||
    legalAidObject.personalDetails.maritalStatus !== "Married to a new partner"
  ) {
    legalAidObject.currentPartnerEvidence = [
      {
        "exEvidence(1)": "",
        secondExEvidenceCheck: formStateLowIncome.checkEvidenceOne,
      },
    ];
    if (
      legalAidObject?.currentPartnerEvidence[0]?.secondExEvidenceCheck === "Yes"
    ) {
      legalAidObject.currentPartnerEvidence.push({
        "exEvidence(2)": "",
        thirdExEvidenceCheck: formStateLowIncome.checkEvidenceTwo,
      });
    }
    if (
      legalAidObject?.currentPartnerEvidence[1]?.thirdExEvidenceCheck === "Yes"
    ) {
      legalAidObject.currentPartnerEvidence.push({
        "exEvidence(3)": "",
        fourthExEvidenceCheck: formStateLowIncome.checkEvidenceThree,
      });
    }
    if (
      legalAidObject?.currentPartnerEvidence[2]?.fourthExEvidenceCheck === "Yes"
    ) {
      legalAidObject.currentPartnerEvidence.push({
        "exEvidence(4)": "",
        fifthExEvidenceCheck: formStateLowIncome.checkEvidenceFour,
      });
    }
    if (
      legalAidObject?.currentPartnerEvidence[3]?.fifthExEvidenceCheck === "Yes"
    ) {
      legalAidObject.currentPartnerEvidence.push({
        "exEvidence(5)": "",
        sixthExEvidenceCheck: formStateLowIncome.checkEvidenceFive,
      });
    }
    if (
      legalAidObject?.currentPartnerEvidence[4]?.sixthExEvidenceCheck === "Yes"
    ) {
      legalAidObject.currentPartnerEvidence.push({
        "exEvidence(6)": "",
        seventhExEvidenceCheck: formStateLowIncome.checkEvidenceSix,
      });
    }
    if (
      legalAidObject?.currentPartnerEvidence[5]?.seventhExEvidenceCheck ===
      "Yes"
    ) {
      legalAidObject.currentPartnerEvidence.push({
        "exEvidence(7)": "",
        eighthExEvidenceCheck: formStateLowIncome.checkEvidenceSeven,
      });
    }
    if (
      legalAidObject?.currentPartnerEvidence[6]?.eighthExEvidenceCheck === "Yes"
    ) {
      legalAidObject.currentPartnerEvidence.push({
        "exEvidence(8)": "",
        ninthExEvidenceCheck: formStateLowIncome.checkEvidenceEight,
      });
    }
    if (
      legalAidObject?.currentPartnerEvidence[7]?.ninthExEvidenceCheck === "Yes"
    ) {
      legalAidObject.currentPartnerEvidence.push({
        "exEvidence(9)": "",
        tenthExEvidenceCheck: formStateLowIncome.checkEvidenceNine,
      });
    }
    if (
      legalAidObject?.currentPartnerEvidence[8]?.tenthExEvidenceCheck === "Yes"
    ) {
      legalAidObject.currentPartnerEvidence.push({
        "exEvidence(10)": "",
      });
    }
  }
  if (legalAidObject.personalDetails.isSurnameAtBirthDifferent === "Yes") {
    legalAidObject.personalDetails.differentSurnameAtBirth =
      formStateLowIncome.birthSurname;
  }
  if (legalAidObject.case.nameInDeedsProperty === "Yes") {
    legalAidObject.case.lastMonthMortgagePayment =
      formStateLowIncome.mortgagePay;
  }
  if (
    legalAidObject.case.accommodationType === "Privately rented" ||
    legalAidObject.case.accommodationType === "Housing association" ||
    legalAidObject.case.accommodationType === "Council property" ||
    legalAidObject.case.accommodationType ===
      "I live with friend or family and I pay rent"
  ) {
    legalAidObject.case.lastMonthRentPay = formStateLowIncome.lastMonthPay;
  }
  if (
    legalAidObject.case.nameInDeedsProperty === "Yes" ||
    legalAidObject.case.familyHomeAndSavingsDiscussion === "Yes"
  ) {
    legalAidObject.properties = [
      {
        propertyWorth: formStateLowIncome.firstPropertyWorth,
        outstandingMortgageProperty: formStateLowIncome.firstPropertyMortgage,
        "isPropertyInJointNamesWithYourEx-partner":
          formStateLowIncome.firstPropertyNames,
        propertyShare: formStateLowIncome.firstPropertyShare,
        isPropertyDiscussion: formStateLowIncome.firstPropertyDiscuss,
        isSecondProperty: formStateLowIncome.secondPropertyCheck,
      },
    ];
    legalAidObject.otherDetails.financiallyResponsibleForYourCurrentPartner =
      formStateLowIncome.financiallyResponsible;
  }
  if (legalAidObject?.properties[0]?.isSecondProperty === "Yes") {
    legalAidObject.properties.push({
      propertyWorth: formStateLowIncome.secondPropertyWorth,
      outstandingMortgageProperty: formStateLowIncome.secondPropertyMortgage,
      "isPropertyInJointNamesWithYourEx-partner":
        formStateLowIncome.secondPropertyNames,
      propertyShare: formStateLowIncome.secondPropertyShare,
      isPropertyDiscussion: formStateLowIncome.secondPropertyDiscuss,
      isThirdProperty: formStateLowIncome.thirdPropertyCheck,
    });
  }
  if (legalAidObject?.properties[1]?.isThirdProperty === "Yes") {
    legalAidObject.properties.push({
      propertyWorth: formStateLowIncome.thirdPropertyWorth,
      outstandingMortgageProperty: formStateLowIncome.thirdPropertyMortgage,
      "isPropertyInJointNamesWithYourEx-partner":
        formStateLowIncome.thirdPropertyNames,
      propertyShare: formStateLowIncome.thirdPropertyShare,
      isPropertyDiscussion: formStateLowIncome.thirdPropertyDiscuss,
    });
  }
  if (legalAidObject.otherDetails.childrenLivingWithYouPermanently === "Yes") {
    legalAidObject.otherDetails.childrenUnder15 =
      formStateLowIncome.childrenUnderFifteen;
    legalAidObject.otherDetails.childrenOver15 =
      formStateLowIncome.childrenAboveFifteen;
  }
  if (legalAidObject.otherDetails.childMaintenancePayment === "Yes") {
    legalAidObject.otherDetails.lastMonthMaintenancePayment =
      formStateLowIncome.maintenancePay;
  }
  if (legalAidObject.otherDetails.criminalLegalAid === "Yes") {
    legalAidObject.otherDetails.criminalLegalAidPerMonthPayment =
      formStateLowIncome.incomeContributionPaymentPerMonth;
  }
  if (
    legalAidObject.personalDetails.maritalStatus ===
      "Married to a new partner" ||
    legalAidObject.personalDetails.maritalStatus ===
      "Cohabiting with new partner"
  ) {
    legalAidObject.partnerFinancialAssets = {
      savingsAmount: formStateLowIncome.savingMoneyAmountOtherParty,
      investmentsAmount: formStateLowIncome.investmentMoneyAmountOtherParty,
      valuableItemsWorth: formStateLowIncome.valuableItemsWorthOtherParty,
      otherCapitalValue: formStateLowIncome.capitalValueOtherParty,
    };
  }
  if (
    legalAidObject.previousRelationshipDetails.beenInMediationBefore === "Yes"
  ) {
    legalAidObject.previousRelationshipDetails.mediationType =
      formStateLowIncome.mediationType;
  }

  client
    .patch(`/legalAidClients/submit/${id}`, legalAidObject, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      uploadFileToDrive(id, formStateLowIncome, setRedirect);
      setIsSubmitted(true);
    })
    .catch((err) => console.log({ err: err.message }));
};
