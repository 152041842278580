import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../Components/FormComponents/TextInput";
import YesNoInput from "../Components/FormComponents/YesNoInput";

const SecondProperty = ({ state, dispatch }) => {
  const [secondPropertyWorth, setSecondPropertyWorth] = useState(
    state.secondPropertyWorth || ""
  );
  const [secondPropertyMortgage, setSecondPropertyMortgage] = useState(
    state.secondPropertyMortgage || ""
  );
  const [secondPropertyNames, setSecondPropertyNames] = useState(
    state.secondPropertyNames
  );
  const [secondPropertyShare, setSecondPropertyShare] = useState(
    state.secondPropertyShare || ""
  );
  const [secondPropertyDiscuss, setSecondPropertyDiscuss] = useState(
    state.secondPropertyDiscuss || ""
  );
  const [thirdPropertyCheck, setThirdPropertyCheck] = useState(
    state.thirdPropertyCheck || ""
  );

  useScrollTo(0, 0, "smooth");

  const onSecondPropertyWorthChange = ({ target }) => {
    setSecondPropertyWorth(target.value);
    dispatch({ type: "SECOND_PROPERTY_WORTH", payload: target.value });
  };
  const onSecondPropertyMortgageChange = ({ target }) => {
    setSecondPropertyMortgage(target.value);
    dispatch({ type: "SECOND_PROPERTY_MORTGAGE", payload: target.value });
  };
  const onSecondPropertyNamesChange = ({ target }) => {
    setSecondPropertyNames(target.value);
    dispatch({ type: "SECOND_PROPERTY_NAMES", payload: target.value });
  };
  const onSecondPropertyShareChange = ({ target }) => {
    setSecondPropertyShare(target.value);
    dispatch({ type: "SECOND_PROPERTY_SHARE", payload: target.value });
  };
  const onSecondPropertyDiscussChange = ({ target }) => {
    setSecondPropertyDiscuss(target.value);
    dispatch({ type: "SECOND_PROPERTY_DISCUSS", payload: target.value });
  };
  const onThirdPropertyCheck = ({ target }) => {
    setThirdPropertyCheck(target.value);
    dispatch({ type: "THIRD_PROPERTY_CHECK", payload: target.value });
  };

  return (
    <>
      <h2>The second property</h2>
      <TextInput
        label="The question should be What do you think the second property is worth?"
        required={true}
        id="secondPropertyWorth"
        value={secondPropertyWorth}
        onChangeText={onSecondPropertyWorthChange}
      />
      <TextInput
        label="What is the outstanding mortgage on the second property?"
        required={true}
        id="secondPropertyMortgage"
        value={secondPropertyMortgage}
        onChangeText={onSecondPropertyMortgageChange}
      />
      <YesNoInput
        label="Is the second property in joint names with your ex-partner?"
        required={true}
        name="secondPropertyNames"
        yesLabel="Yes"
        noLabel="No"
        yesId="yes-second-property-name"
        noId="no-second-property-name"
        checkedTerm={secondPropertyNames}
        onOptionChange={onSecondPropertyNamesChange}
      />
      <TextInput
        label="What is your share of the second property?"
        guide="Enter 50% when the property is jointly owned with your ex-partner.
        Select 100% when the property is only owned by you."
        id="secondPropertyShare"
        anotherText="Enter an appropriate % if another person has a percentage interest."
        value={secondPropertyShare}
        onChangeText={onSecondPropertyShareChange}
      />
      <YesNoInput
        label="Are you willing to discuss this property during mediation?"
        name="secondPropertyDiscuss"
        yesLabel="Yes"
        noLabel="No"
        yesId="yes-second-property-discussion"
        noId="no-second-property-discussion"
        required={true}
        checkedTerm={secondPropertyDiscuss}
        onOptionChange={onSecondPropertyDiscussChange}
      />
      <YesNoInput
        label="Do you own a third property?"
        name="thirdPropertyCheck"
        yesLabel="Yes"
        noLabel="No"
        yesId="yes-third-property-check"
        noId="no-third-property-check"
        required={true}
        checkedTerm={thirdPropertyCheck}
        onOptionChange={onThirdPropertyCheck}
      />
    </>
  );
};

export default SecondProperty;
