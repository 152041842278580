import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../Components/FormComponents/TextInput";
import YesNoInput from "../Components/FormComponents/YesNoInput";

const ThirdProperty = ({ state, dispatch }) => {
  const [thirdPropertyWorth, setThirdPropertyWorth] = useState(
    state.thirdPropertyWorth || ""
  );
  const [thirdPropertyMortgage, setThirdPropertyMortgage] = useState(
    state.thirdPropertyMortgage || ""
  );
  const [thirdPropertyNames, setThirdPropertyNames] = useState(
    state.thirdPropertyNames
  );
  const [thirdPropertyShare, setThirdPropertyShare] = useState(
    state.thirdPropertyShare || ""
  );
  const [thirdPropertyDiscuss, setThirdPropertyDiscuss] = useState(
    state.thirdPropertyDiscuss || ""
  );

  useScrollTo(0, 0, "smooth");

  const onThirdPropertyWorthChange = ({ target }) => {
    setThirdPropertyWorth(target.value);
    dispatch({ type: "THIRD_PROPERTY_WORTH", payload: target.value });
  };
  const onThirdPropertyMortgageChange = ({ target }) => {
    setThirdPropertyMortgage(target.value);
    dispatch({ type: "THIRD_PROPERTY_MORTGAGE", payload: target.value });
  };
  const onThirdPropertyNamesChange = ({ target }) => {
    setThirdPropertyNames(target.value);
    dispatch({ type: "THIRD_PROPERTY_NAMES", payload: target.value });
  };
  const onThirdPropertyShareChange = ({ target }) => {
    setThirdPropertyShare(target.value);
    dispatch({ type: "THIRD_PROPERTY_SHARE", payload: target.value });
  };
  const onThirdPropertyDiscussChange = ({ target }) => {
    setThirdPropertyDiscuss(target.value);
    dispatch({ type: "THIRD_PROPERTY_DISCUSS", payload: target.value });
  };

  return (
    <>
      <h2>The third property</h2>
      <TextInput
        label="The question should be What do you think the third property is worth?"
        required={true}
        id="thirdPropertyWorth"
        value={thirdPropertyWorth}
        onChangeText={onThirdPropertyWorthChange}
      />
      <TextInput
        label="What is the outstanding mortgage on the third property?"
        required={true}
        id="thirdPropertyMortgage"
        value={thirdPropertyMortgage}
        onChangeText={onThirdPropertyMortgageChange}
      />
      <YesNoInput
        label="Is the third property in joint names with your ex-partner?"
        required={true}
        name="thirdPropertyNames"
        yesLabel="Yes"
        noLabel="No"
        yesId="yes-third-property-name"
        noId="no-third-property-name"
        checkedTerm={thirdPropertyNames}
        onOptionChange={onThirdPropertyNamesChange}
      />
      <TextInput
        label="What is your share of the third property?"
        guide="Enter 50% when the property is jointly owned with your ex-partner.
        Select 100% when the property is only owned by you."
        id="thirdPropertyShare"
        anotherText="Enter an appropriate % if another person has a percentage interest."
        value={thirdPropertyShare}
        onChangeText={onThirdPropertyShareChange}
      />
      <YesNoInput
        label="Are you willing to discuss this property during mediation?"
        name="thirdPropertyDiscuss"
        yesLabel="Yes"
        noLabel="No"
        yesId="yes-third-property-discussion"
        noId="no-third-property-discussion"
        required={true}
        checkedTerm={thirdPropertyDiscuss}
        onOptionChange={onThirdPropertyDiscussChange}
      />
    </>
  );
};

export default ThirdProperty;
