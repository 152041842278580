import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../Components/FormComponents/TextInput";
import YesNoInput from "../Components/FormComponents/YesNoInput";
import SelectInput from "../Components/FormComponents/SelectInput";

const PrevRelationshipDetails = ({ state, dispatch }) => {
  const [otherPartySurname, setOtherPartySurname] = useState(
    state.otherPartySurname || ""
  );
  const [marriedCheck, setMarriedCheck] = useState(state.marriedCheck);
  const [mediationBefore, setMediationBefore] = useState(state.mediationBefore);
  const [mediationType, setMediationType] = useState(
    state.mediationType || "select"
  );

  useScrollTo(0, 0, "smooth");

  const onOtherPartySurname = ({ target }) => {
    setOtherPartySurname(target.value);
    dispatch({ type: "OTHER_PARTY_SURNAME", payload: target.value });
  };
  const onMarriedCheck = ({ target }) => {
    setMarriedCheck(target.value);
    dispatch({ type: "MARRIED_CHECK", payload: target.value });
  };
  const onMediationBeforeChange = ({ target }) => {
    setMediationBefore(target.value);
    dispatch({ type: "MEDIATION_BEFORE", payload: target.value });
  };
  const onMediationTypeChange = ({ target }) => {
    setMediationType(target.value);
    dispatch({ type: "MEDIATION_TYPE", payload: target.value });
  };

  const mediationTypeOptions = [
    "Child arrangements",
    "Financial arrangements",
    "Both issues",
  ];

  return (
    <>
      <h2>Your previous relationship's details</h2>
      <TextInput
        label="What is the surname of the other participant of the conflict?"
        value={otherPartySurname}
        onChangeText={onOtherPartySurname}
        guide="(This is usually your ex-partner)"
        id="otherPartySurname"
        required={true}
      />
      <YesNoInput
        label="Were you married with the other participant in the conflict?"
        required={true}
        checkedTerm={marriedCheck}
        onOptionChange={onMarriedCheck}
        name="marriedCheck"
        yesLabel="Yes"
        noLabel="No"
        yesId="yes-married-check"
        noId="no-married-check"
      />
      <YesNoInput
        label="Have you been in mediation before?"
        required={true}
        checkedTerm={mediationBefore}
        onOptionChange={onMediationBeforeChange}
        name="mediationBefore"
        yesLabel="Yes"
        noLabel="No"
        yesId="yes-mediation-before"
        noId="no-mediation-before"
      />
      {mediationBefore === "Yes" && (
        <SelectInput
          label="What type of mediation did you have?"
          required={true}
          optionValue={mediationType}
          options={mediationTypeOptions}
          onOptionChange={onMediationTypeChange}
        />
      )}
    </>
  );
};

export default PrevRelationshipDetails;
