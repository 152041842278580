import React, { useState, useEffect } from "react";
import useScrollTo from "../hooks/useScrollTo";
import YesNoInput from "../Components/FormComponents/YesNoInput";

const Evidence = ({ state, dispatch }) => {
  const [evidenceOne, setEvidenceOne] = useState(state.evidenceOne);
  const [checkOne, setCheckOne] = useState(state.checkOne);
  const [evidenceTwo, setEvidenceTwo] = useState(state.evidenceTwo);
  const [checkTwo, setCheckTwo] = useState(state.checkTwo);
  const [evidenceThree, setEvidenceThree] = useState(state.evidenceThree);
  const [checkThree, setCheckThree] = useState(state.checkThree);
  const [evidenceFour, setEvidenceFour] = useState(state.evidenceFour);
  const [checkFour, setCheckFour] = useState(state.checkFour);
  const [evidenceFive, setEvidenceFive] = useState(state.evidenceFive);
  const [checkFive, setCheckFive] = useState(state.checkFive);
  const [evidenceSix, setEvidenceSix] = useState(state.evidenceSix);
  const [checkSix, setCheckSix] = useState(state.checkSix);
  const [evidenceSeven, setEvidenceSeven] = useState(state.evidenceSeven);
  const [checkSeven, setCheckSeven] = useState(state.checkSeven);
  const [evidenceEight, setEvidenceEight] = useState(state.evidenceEight);
  const [checkEight, setCheckEight] = useState(state.checkEight);
  const [evidenceNine, setEvidenceNine] = useState(state.evidenceNine);
  const [checkNine, setCheckNine] = useState(state.checkNine);
  const [evidenceTen, setEvidenceTen] = useState(state.evidenceTen);
  const [errorOne, setErrorOne] = useState("");
  const [errorTwo, setErrorTwo] = useState("");
  const [errorThree, setErrorThree] = useState("");
  const [errorFour, setErrorFour] = useState("");
  const [errorFive, setErrorFive] = useState("");
  const [errorSix, setErrorSix] = useState("");
  const [errorSeven, setErrorSeven] = useState("");
  const [errorEight, setErrorEight] = useState("");
  const [errorNine, setErrorNine] = useState("");
  const [errorTen, setErrorTen] = useState("");

  useEffect(() => {
    if (state?.evidenceOne?.name === undefined) {
      setErrorOne(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorOne("");
    }
    if (state?.evidenceTwo?.name === undefined) {
      setErrorTwo(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorTwo("");
    }
    if (state?.evidenceThree?.name === undefined) {
      setErrorThree(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorThree("");
    }
    if (state?.evidenceFour?.name === undefined) {
      setErrorFour(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorFour("");
    }
    if (state?.evidenceFive?.name === undefined) {
      setErrorFive(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorFive("");
    }
    if (state?.evidenceSix?.name === undefined) {
      setErrorSix(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorSix("");
    }
    if (state?.evidenceSeven?.name === undefined) {
      setErrorSeven(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorSeven("");
    }
    if (state?.evidenceEight?.name === undefined) {
      setErrorEight(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorEight("");
    }
    if (state?.evidenceNine?.name === undefined) {
      setErrorNine(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorNine("");
    }
    if (state?.evidenceTen?.name === undefined) {
      setErrorTen(
        "No document has been attached. Please add a document or image"
      );
    } else {
      setErrorTen("");
    }
  }, [
    state?.evidenceOne?.name,
    state?.evidenceTwo?.name,
    state?.evidenceThree?.name,
    state?.evidenceFour?.name,
    state?.evidenceFive?.name,
    state?.evidenceSix?.name,
    state?.evidenceSeven?.name,
    state?.evidenceEight?.name,
    state?.evidenceNine?.name,
    state?.evidenceTen?.name,
  ]);

  useScrollTo(0, 0, "smooth");

  const onEvidenceOneHandler = (event) => {
    setEvidenceOne(event.target.files[0]);
  };
  const onEvidenceTwoHandler = (event) => {
    setEvidenceTwo(event.target.files[0]);
  };
  const onEvidenceThreeHandler = (event) => {
    setEvidenceThree(event.target.files[0]);
  };
  const onEvidenceFourHandler = (event) => {
    setEvidenceFour(event.target.files[0]);
  };
  const onEvidenceFiveHandler = (event) => {
    setEvidenceFive(event.target.files[0]);
  };
  const onEvidenceSixHandler = (event) => {
    setEvidenceSix(event.target.files[0]);
  };
  const onEvidenceSevenHandler = (event) => {
    setEvidenceSeven(event.target.files[0]);
  };
  const onEvidenceEightHandler = (event) => {
    setEvidenceEight(event.target.files[0]);
  };
  const onEvidenceNineHandler = (event) => {
    setEvidenceNine(event.target.files[0]);
  };
  const onEvidenceTenHandler = (event) => {
    setEvidenceTen(event.target.files[0]);
  };

  const onCheckOne = ({ target }) => {
    setCheckOne(target.value);
    dispatch({ type: "CHECK_ONE", payload: target.value });
  };
  const onCheckTwo = ({ target }) => {
    setCheckTwo(target.value);
    dispatch({ type: "CHECK_TWO", payload: target.value });
  };
  const onCheckThree = ({ target }) => {
    setCheckThree(target.value);
    dispatch({ type: "CHECK_THREE", payload: target.value });
  };
  const onCheckFour = ({ target }) => {
    setCheckFour(target.value);
    dispatch({ type: "CHECK_FOUR", payload: target.value });
  };
  const onCheckFive = ({ target }) => {
    setCheckFive(target.value);
    dispatch({ type: "CHECK_FIVE", payload: target.value });
  };
  const onCheckSix = ({ target }) => {
    setCheckSix(target.value);
    dispatch({ type: "CHECK_SIX", payload: target.value });
  };
  const onCheckSeven = ({ target }) => {
    setCheckSeven(target.value);
    dispatch({ type: "CHECK_SEVEN", payload: target.value });
  };
  const onCheckEight = ({ target }) => {
    setCheckEight(target.value);
    dispatch({ type: "CHECK_EIGHT", payload: target.value });
  };
  const onCheckNine = ({ target }) => {
    setCheckNine(target.value);
    dispatch({ type: "CHECK_NINE", payload: target.value });
  };

  useEffect(() => {
    dispatch({ type: "EVIDENCE_ONE", payload: evidenceOne });
    dispatch({ type: "EVIDENCE_TWO", payload: evidenceTwo });
    dispatch({ type: "EVIDENCE_THREE", payload: evidenceThree });
    dispatch({ type: "EVIDENCE_FOUR", payload: evidenceFour });
    dispatch({ type: "EVIDENCE_FIVE", payload: evidenceFive });
    dispatch({ type: "EVIDENCE_SIX", payload: evidenceSix });
    dispatch({ type: "EVIDENCE_SEVEN", payload: evidenceSeven });
    dispatch({ type: "EVIDENCE_EIGHT", payload: evidenceEight });
    dispatch({ type: "EVIDENCE_NINE", payload: evidenceNine });
    dispatch({ type: "EVIDENCE_TEN", payload: evidenceTen });
  }, [
    dispatch,
    evidenceOne,
    evidenceTwo,
    evidenceThree,
    evidenceFour,
    evidenceFive,
    evidenceSix,
    evidenceSeven,
    evidenceEight,
    evidenceNine,
    evidenceTen,
  ]);

  return (
    <>
      <h2>Evidence</h2>
      <>
        <h3>
          Submit your evidence (1)<span className="color-red">*</span>
        </h3>
        <div className="form-control colum-direction">
          <label htmlFor="evidence-1" className="file-label">
            Upload File - {state?.evidenceOne?.name}
          </label>
          <input
            type="file"
            name="evidence-1"
            onChange={onEvidenceOneHandler}
            required={state?.evidenceOne?.name === undefined ? true : false}
            style={{
              display: "none",
            }}
            id="evidence-1"
          />
        </div>
        {<p style={{ color: "red" }}>{errorOne}</p>}
        <YesNoInput
          label="Do you have more documents to upload?"
          yesLabel="Yes"
          noLabel="No"
          yesId="yes-check-one"
          noId="no-check-one"
          name="check-one"
          checkedTerm={checkOne}
          onOptionChange={onCheckOne}
          required={true}
        />
      </>
      {checkOne === "Yes" && (
        <>
          <h3>
            Submit your evidence (2)<span className="color-red">*</span>
          </h3>
          <div className="form-control colum-direction">
            <label htmlFor="evidence-2" className="file-label">
              Upload File - {state?.evidenceTwo?.name}
            </label>
            <input
              type="file"
              name="evidence-2"
              id="evidence-2"
              required={state?.evidenceTwo?.name === undefined ? true : false}
              onChange={onEvidenceTwoHandler}
              style={{
                display: "none",
              }}
            />
          </div>
          {<p style={{ color: "red" }}>{errorTwo}</p>}
          <YesNoInput
            label="Do you have more documents to upload?"
            yesLabel="Yes"
            noLabel="No"
            yesId="yes-check-two"
            noId="no-check-two"
            name="check-two"
            checkedTerm={checkTwo}
            onOptionChange={onCheckTwo}
            required={true}
          />
        </>
      )}
      {checkOne === "Yes" && checkTwo === "Yes" && (
        <>
          <h3>
            Submit your evidence (3)<span className="color-red">*</span>
          </h3>
          <div className="form-control colum-direction">
            <label htmlFor="evidence-3" className="file-label">
              Upload File - {state?.evidenceThree?.name}{" "}
            </label>
            <input
              type="file"
              name="evidence-3"
              id="evidence-3"
              onChange={onEvidenceThreeHandler}
              required={state?.evidenceThree?.name === undefined ? true : false}
              style={{
                display: "none",
              }}
            />
          </div>
          {<p style={{ color: "red" }}>{errorThree}</p>}
          <YesNoInput
            label="Do you have more documents to upload?"
            yesLabel="Yes"
            noLabel="No"
            yesId="yes-check-three"
            noId="no-check-three"
            name="check-three"
            checkedTerm={checkThree}
            onOptionChange={onCheckThree}
            required={true}
          />
        </>
      )}
      {checkOne === "Yes" && checkThree === "Yes" && checkTwo === "Yes" && (
        <>
          <h3>
            Submit your evidence (4)<span className="color-red">*</span>
          </h3>
          <div className="form-control colum-direction">
            <label htmlFor="evidence-4" className="file-label">
              Upload File - {state?.evidenceFour?.name}
            </label>
            <input
              type="file"
              name="evidence-4"
              id="evidence-4"
              onChange={onEvidenceFourHandler}
              required={state?.evidenceFour?.name === undefined ? true : false}
              style={{
                display: "none",
              }}
            />
          </div>
          {<p style={{ color: "red" }}>{errorFour}</p>}
          <YesNoInput
            label="Do you have more documents to upload?"
            yesLabel="Yes"
            noLabel="No"
            yesId="yes-check-four"
            noId="no-check-four"
            name="check-four"
            checkedTerm={checkFour}
            onOptionChange={onCheckFour}
            required={true}
          />
        </>
      )}
      {checkOne === "Yes" &&
        checkThree === "Yes" &&
        checkFour === "Yes" &&
        checkTwo === "Yes" && (
          <>
            <h3>
              Submit your evidence (5)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-5" className="file-label">
                Upload File - {state?.evidenceFive?.name}
              </label>
              <input
                type="file"
                name="evidence-5"
                id="evidence-5"
                onChange={onEvidenceFiveHandler}
                required={
                  state?.evidenceFive?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
              />
            </div>
            {<p style={{ color: "red" }}>{errorFive}</p>}
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-five"
              noId="no-check-five"
              name="check-five"
              checkedTerm={checkFive}
              onOptionChange={onCheckFive}
              required={true}
            />
          </>
        )}
      {checkOne === "Yes" &&
        checkThree === "Yes" &&
        checkFour === "Yes" &&
        checkTwo === "Yes" &&
        checkFive === "Yes" && (
          <>
            <h3>
              Submit your evidence (6)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-6" className="file-label">
                Upload File - {state?.evidenceSix?.name}{" "}
              </label>
              <input
                type="file"
                name="evidence-6"
                id="evidence-6"
                onChange={onEvidenceSixHandler}
                required={state?.evidenceSix?.name === undefined ? true : false}
                style={{
                  display: "none",
                }}
              />
            </div>
            {<p style={{ color: "red" }}>{errorSix}</p>}
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-six"
              noId="no-check-six"
              name="check-six"
              checkedTerm={checkSix}
              onOptionChange={onCheckSix}
              required={true}
            />
          </>
        )}
      {checkOne === "Yes" &&
        checkThree === "Yes" &&
        checkFour === "Yes" &&
        checkTwo === "Yes" &&
        checkFive === "Yes" &&
        checkSix === "Yes" && (
          <>
            <h3>
              Submit your evidence (7)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-7" className="file-label">
                Upload File - {state?.evidenceSeven?.name}{" "}
              </label>
              <input
                type="file"
                name="evidence-7"
                id="evidence-7"
                onChange={onEvidenceSevenHandler}
                required={
                  state?.evidenceSeven?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
              />
            </div>
            {<p style={{ color: "red" }}>{errorSeven}</p>}
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-seven"
              noId="no-check-seven"
              name="check-seven"
              checkedTerm={checkSeven}
              onOptionChange={onCheckSeven}
              required={true}
            />
          </>
        )}
      {checkOne === "Yes" &&
        checkThree === "Yes" &&
        checkFour === "Yes" &&
        checkTwo === "Yes" &&
        checkFive === "Yes" &&
        checkSix === "Yes" &&
        checkSeven === "Yes" && (
          <>
            <h3>
              Submit your evidence (8)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-8" className="file-label">
                Upload File - {state?.evidenceEight?.name}
              </label>
              <input
                type="file"
                name="evidence-8"
                id="evidence-8"
                onChange={onEvidenceEightHandler}
                required={
                  state?.evidenceEight?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
              />
            </div>
            {<p style={{ color: "red" }}>{errorEight}</p>}
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-eight"
              noId="no-check-eight"
              name="check-eight"
              checkedTerm={checkEight}
              onOptionChange={onCheckEight}
              required={true}
            />
          </>
        )}
      {checkOne === "Yes" &&
        checkThree === "Yes" &&
        checkFour === "Yes" &&
        checkTwo === "Yes" &&
        checkFive === "Yes" &&
        checkSix === "Yes" &&
        checkSeven === "Yes" &&
        checkEight === "Yes" && (
          <>
            <h3>
              Submit your evidence (9)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-9" className="file-label">
                Upload File - {state?.evidenceNine?.name}
              </label>
              <input
                type="file"
                name="evidence-9"
                onChange={onEvidenceNineHandler}
                required={
                  state?.evidenceNine?.name === undefined ? true : false
                }
                style={{
                  display: "none",
                }}
                id="evidence-9"
              />
            </div>
            {<p style={{ color: "red" }}>{errorNine}</p>}
            <YesNoInput
              label="Do you have more documents to upload?"
              yesLabel="Yes"
              noLabel="No"
              yesId="yes-check-nine"
              noId="no-check-nine"
              name="check-nine"
              checkedTerm={checkNine}
              onOptionChange={onCheckNine}
              required={true}
            />
          </>
        )}
      {checkOne === "Yes" &&
        checkThree === "Yes" &&
        checkFour === "Yes" &&
        checkTwo === "Yes" &&
        checkFive === "Yes" &&
        checkSix === "Yes" &&
        checkSeven === "Yes" &&
        checkEight === "Yes" &&
        checkNine === "Yes" && (
          <>
            <h3>
              Submit your evidence (10)<span className="color-red">*</span>
            </h3>
            <div className="form-control colum-direction">
              <label htmlFor="evidence-10" className="file-label">
                Upload File - {state?.evidenceTen?.name}
              </label>
              <input
                type="file"
                name="evidence-10"
                onChange={onEvidenceTenHandler}
                required={state?.evidenceTen?.name === undefined ? true : false}
                style={{
                  display: "none",
                }}
                id="evidence-10"
              />
            </div>
            {<p style={{ color: "red" }}>{errorTen}</p>}
          </>
        )}
    </>
  );
};

export default Evidence;
