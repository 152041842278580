import { useEffect, useState } from "react";
import SignatureCanvas from "react-signature-canvas";

import YesNoInput from "../Components/FormComponents/YesNoInput";
import useScrollTo from "../hooks/useScrollTo";

const ClientDeclaration = ({ state, dispatch }) => {
  const [informationCheck, setInformationCheck] = useState(
    state.informationCheck
  );
  const [relatedDiscussionsUsed, setRelatedDiscussionsUsed] = useState(
    state.relatedDiscussionsUsed
  );
  const [refusalInformation, setRefusalInformation] = useState(
    state.refusalInformation
  );
  const [signature, setSignature] = useState(null);
  const [url, setUrl] = useState(state.url || "");

  useScrollTo(0, 0, "smooth");

  const onInformationCheck = ({ target }) => {
    setInformationCheck(target.value);
    dispatch({ type: "INFORMATION_CHECK", payload: target.value });
  };
  const onRelatedDiscussionsUse = ({ target }) => {
    setRelatedDiscussionsUsed(target.value);
    dispatch({ type: "REFUSAL_INFO", payload: target.value });
  };

  const onRefusalInfoCheck = ({ target }) => {
    setRefusalInformation(target.value);
    dispatch({ type: "REFUSAL_DISCUSSION", payload: target.value });
  };

  const onClearHandler = () => {
    signature.clear();
  };
  const onSaveHandler = () => {
    setUrl(signature.getTrimmedCanvas().toDataURL("image/png"));
  };
  useEffect(() => {
    dispatch({ type: "URL", payload: url });
  }, [url, dispatch]);
  return (
    <>
      <h2>Client declaration's</h2>
      <YesNoInput
        label="I understand that the Legal Aid Agency may check whether the information and documentation that I provide is genuine and correct."
        checkedTerm={informationCheck}
        onOptionChange={onInformationCheck}
        name="information-check"
        noLabel="No"
        yesLabel="Yes"
        noId="no-information-check"
        yesId="yes-information-check"
        required={true}
      />
      {informationCheck === "No" && (
        <div className="danger">
          Because you have answered NO to this question you are unable to
          continue with this application
        </div>
      )}
      <YesNoInput
        label="I understand that the information obtained will be used to decide my application for Legal Aid and for related purposes."
        checkedTerm={relatedDiscussionsUsed}
        onOptionChange={onRelatedDiscussionsUse}
        name="related-discussions"
        noLabel="No"
        yesLabel="Yes"
        noId="no-related-discussions"
        yesId="yes-related-discussions"
        required={true}
      />
      {relatedDiscussionsUsed === "No" && (
        <div className="danger">
          Because you have answered NO to this question you are unable to
          continue with this application
        </div>
      )}
      <YesNoInput
        label="I understand that providing information or documentation that is not genuine and/or correct will result in my application (or subsequent applications) being refused and may lead to my prosecution for a criminal offence."
        checkedTerm={refusalInformation}
        onOptionChange={onRefusalInfoCheck}
        name="refusal-info"
        noLabel="No"
        yesLabel="Yes"
        noId="no-refusal-info"
        yesId="yes-refusal-info"
        required={true}
      />
      {refusalInformation === "No" && (
        <div className="danger">
          Because you have answered NO to this question you are unable to
          continue with this application
        </div>
      )}
      <div>
        <p
          style={{
            marginBottom: "5px",
            fontWeight: "500",
          }}
        >
          Please sign your application below:
        </p>
        <div className="signature">
          <p>Electronic signature</p>
          <SignatureCanvas ref={(data) => setSignature(data)} />
        </div>
        <input
          type="button"
          onClick={onClearHandler}
          value="Clear"
          style={{
            padding: "0.5rem",
            margin: "0.5rem",
            borderRadius: "0.5rem",
            cursor: "pointer",
          }}
        />
        <input
          type="button"
          onClick={onSaveHandler}
          value="Save"
          style={{
            padding: "0.5rem",
            margin: "0.5rem",
            borderRadius: "0.5rem",
            cursor: "pointer",
          }}
          required
        />
      </div>
    </>
  );
};

export default ClientDeclaration;
