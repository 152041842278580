import { useState } from "react";
import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../Components/FormComponents/TextInput";

const PrevFinancialDetails = ({ state, dispatch }) => {
  const [savingMoneyAmountOtherParty, setSavingMoneyAmountOtherParty] =
    useState(state.savingMoneyAmountOtherParty || "");
  const [investmentMoneyAmountOtherParty, setInvestmentMoneyAmountOtherParty] =
    useState(state.investmentMoneyAmountOtherParty || "");
  const [valuableItemsWorthOtherParty, setValuableItemsWorthOtherParty] =
    useState(state.valuableItemsWorthOtherParty || "");
  const [capitalValueOtherParty, setCapitalValueOtherParty] = useState(
    state.capitalValueOtherParty || ""
  );

  useScrollTo(0, 0, "smooth");

  const onSavingMoneyAmountOtherPartyChange = ({ target }) => {
    setSavingMoneyAmountOtherParty(target.value);
    dispatch({ type: "SAVE_MONEY_OTHER_PARTY", payload: target.value });
  };
  const onInvestmentMoneyAmountOtherPartyChange = ({ target }) => {
    setInvestmentMoneyAmountOtherParty(target.value);
    dispatch({ type: "INVESTMENT_MONEY_OTHER_PARTY", payload: target.value });
  };
  const onValuableItemsWorthOtherPartyChange = ({ target }) => {
    setValuableItemsWorthOtherParty(target.value);
    dispatch({ type: "VALUABLE_ITEMS_OTHER_PARTY", payload: target.value });
  };
  const onCapitalValueOtherPartyChange = ({ target }) => {
    setCapitalValueOtherParty(target.value);
    dispatch({ type: "CAPITAL_VALUE_OTHER_PARTY", payload: target.value });
  };
  return (
    <>
      <h2>Your partner's financial details</h2>
      <TextInput
        label="Please tell us the amount of money your partner has in savings (bank, building society, etc)"
        required={true}
        value={savingMoneyAmountOtherParty}
        onChangeText={onSavingMoneyAmountOtherPartyChange}
        id="savingMoneyAmount"
        guide="Please write it as a round figure."
      />
      <TextInput
        label="Please tell us the amount of money your partner has in investments (shares, insurance policies etc)"
        required={true}
        value={investmentMoneyAmountOtherParty}
        onChangeText={onInvestmentMoneyAmountOtherPartyChange}
        id="investmentMoney"
        guide="Please write it as a round figure."
      />
      <TextInput
        label="Please tell us how much your partner's valuable items are worth (boat, caravan, jewellery, etc) (This doesn't include cars unless their value is more than £15,000.00)."
        value={valuableItemsWorthOtherParty}
        onChangeText={onValuableItemsWorthOtherPartyChange}
        id="valuableItemsWorth"
        guide="Please write it as a round figure."
      />
      <TextInput
        label="Please tell us the value of any other capital (including money due to the client) of your partner"
        required={true}
        value={capitalValueOtherParty}
        onChangeText={onCapitalValueOtherPartyChange}
        id="capitalValue"
        guide="Please write it as a round figure."
      />
    </>
  );
};

export default PrevFinancialDetails;
